import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {blurNodeProperty, focusNodeProperty, putNodeProperty, putNodesProperty} from "../../../../actions";
import 'date-fns';
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import ExecutionSelector from "../../../components/ExecutionSelector";
import ExecutionShowQuestionAddLink from "../../ExecutionShowQuestionAddLink";
import {NODE_TYPE_OPTIONS} from "../../../../reducers/graphReducer";
import {GLOBAL_SEARCH_TYPES} from "../../../../util/constants";
import {canAddExecution} from "../../../../selectors/procedureSelectors";

class ExecutionQuestionSelectExecution extends ExecutionQuestionInputBase {
    state = {
        createLinkModalOpen: false,
    }

    handleChangeSelect = (selection) => {
        let {field, selectMany} = this.props;
        if (selection == null) {
            this.updateNodeProperty(field, null, field);
        } else {
            let selectedItems = Array.isArray(selection) ? selection : [selection];
            let values = selectedItems.map(a => a.id || a.value);
            this.updateNodeProperty(field, selectMany ? values : values[0], field);
        }
    };

    handleCreateOption = () => {
        const {executionSelectorId} = this.props;
        const target = document.getElementById(executionSelectorId);
        this.setState({addAnchorEl: target});
    }

    handleAddClose = () => {
        this.setState({addAnchorEl: null});
    };

    handleCreate = (execution) => {
        const {selectedOption, selectorId, onPutNodeProperty} = this.props;
        const values = selectedOption.concat(execution);
        this.handleChangeSelect(values);
        this.handleAddClose();
        onPutNodeProperty({id: selectorId, searchTerm: null});
    }

    render = () => {
        const {
            questionId,
            disabled,
            selectMany,
            selectedOption,
            procedureIds,
            executionId,
            field,
            classes,
            canCreate,
            where,
            orderBy,
            orderByDirection
        } = this.props;
        const {addAnchorEl} = this.state;
        return <>
            <ExecutionSelector
                procedureIds={procedureIds}
                where={where}
                multiple={selectMany}
                value={selectedOption}
                handleChange={this.handleChangeSelect}
                handleFocusElement={this.handleFocusElement}
                handleBlurElement={this.handleBlurElement}
                disabled={disabled}
                id={questionId + '_' + field}
                customId={questionId + '_' + field}
                propertyName={'initialValue-' + questionId}
                renderOptionKey={false}
                mobileView={true}
                customClass={classes.customClass}
                canCreate={canCreate}
                handleCreateOption={this.handleCreateOption}
                searchTypes={[GLOBAL_SEARCH_TYPES.workItems.id]}
                menuPortalTarget={document.body}
                defaultOptions={true}
                workItemSearch={true}
                executionId={executionId}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                executionLoaderDisabled={true}
            />
            {
                <ExecutionShowQuestionAddLink
                    anchorEl={addAnchorEl}
                    questionId={questionId}
                    onClose={this.handleAddClose}
                    handleCreate={this.handleCreate}/>
            }
        </>
    };
}

const styles = () => ({
    customClass: {
        '& .select__indicators': {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
        },
        '& .select__dropdown-indicator': {
            padding: 3
        },
        '& .select__clear-indicator': {
            marginRight: 4,
        },
        '& .select__placeholder': {
            width: 'calc(100% - 30px)'
        },
        '& .select__placeholder .MuiFormLabel-root': {
            marginBottom: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        '& .select__input-container': {
            display: 'grid',
        },
    }
});
ExecutionQuestionSelectExecution.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    const executionSelectorId = ownProps.questionId + '_' + ownProps.field;
    let selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + '-' + executionSelectorId;
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let value = executionQuestionNode[ownProps.field];
    let values = Array.isArray(value) ? value : (value ? [value] : []);
    let options = executionQuestionNode.optionsParsed ?? [];
    let defaultOptions = options.map(a => ({id: a.value, title: a.label}));
    let selectOptionsByKey = {};
    for (let selectOption of defaultOptions) {
        selectOptionsByKey[selectOption.id] = selectOption.title;
    }
    let selectedOption = values.map(a => ({id: a, title: selectOptionsByKey[a] || 'Error loading name'}));
    if (Array.isArray(value)) {

    } else if (value) {
        selectedOption = defaultOptions.filter(a => values.indexOf(a.id) !== -1);
    }
    const canAdd = canAddExecution(state, executionQuestionNode.linkedAddOptions);
    return {
        options: options,
        selectedOption: selectedOption,
        defaultOptions: true,
        selectMany: executionQuestionNode.selectMany,
        procedureIds: executionQuestionNode?.selectExecutionFilter?.procedureIds || [],
        where: executionQuestionNode?.selectExecutionFilter?.where || null,
        orderBy: executionQuestionNode?.selectExecutionFilter?.orderBy || null,
        orderByDirection: executionQuestionNode?.selectExecutionFilter?.orderByDirection || null,
        selectorId: selectorId,
        executionSelectorId: executionSelectorId,
        canCreate: canAdd
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSelectExecution));
