import {
    getActiveChildrenDescendantsAndSelf,
    getActiveDescendants,
    getActiveDescendantsAndSelfIfPresent,
    getNodeOrError,
    getNodeOrNull,
    getNodeSchemaOrNull,
    getNodesOrError,
    getRootNodeOrError
} from "../selectors/graphSelectors";
import {
    COMPLETE_ACTION_STYLES,
    COMPLETE_LABELS_FORMAT,
    CONDITIONAL_VALUES,
    EXECUTION_FILTER_MODE,
    EXECUTION_ORDER_BY_DIRECTION,
    EXECUTION_SEARCH_COLUMNS,
    EXECUTION_SEARCH_FORMATS,
    EXECUTION_SEARCH_VIEWS,
    EXECUTION_STANDARD_PIVOT_COLUMNS,
    FILTER_BY_EXACT_MODE,
    GRANT_DENY_PERMISSIONS,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    PROCEDURE_LINK_STYLE,
    PROCEDURE_TYPES,
    QUESTION_TYPES,
    RULE_ACTION_TYPE,
    SELECT_DATA_SOURCES
} from "../reducers/graphReducer";
import {createChildNode, createNode} from "./graphFactory";
import {getExecutionSummaryFullByIdIfPresent} from "../selectors/executionSelectors";
import {isEqual} from "lodash";
import {
    convertJsonLogicForQuery,
    extractJsonLogicProperties,
    isStandardField,
    isStandardPivotField
} from "./sopJsonLogic";
import {
    getActiveChildRuleByActionOrNull,
    getActiveChildRulesByAction,
    getActiveRulesByActionForNode,
    getActiveRulesForNode,
    getChildRulesByAction
} from "../selectors/ruleSelectors";
import {ROLES} from "../permissions";
import {convertFormulaToJsonLogic, rewriteStoredExpressionToHuman} from "tbf-jsonlogic";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {isNodeSaved} from "./executionFactory";


export const computeTableViews = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node) {
        return [];
    }

    const viewRules = getActiveRulesByActionForNode(state, nodeId, RULE_ACTION_TYPE.collectionView.id);
    return viewRules.map(a => ({id: a.id, name: a.message}));
}

export const computeDefaultColumns = (state, selectorId) => {
    const node = getNodeOrError(state, selectorId);
    const columns = [];
    const isVeryMixedTypes = !node.procedureId && !node.questionId;
    if (node.questionId) {
        columns.push(EXECUTION_SEARCH_COLUMNS.name);
        columns.push(EXECUTION_SEARCH_COLUMNS.status);
        columns.push(EXECUTION_SEARCH_COLUMNS.completedRatio);
        columns.push(EXECUTION_SEARCH_COLUMNS.totalPhotoCount);
    } else {
        if (isVeryMixedTypes) {
            columns.push(EXECUTION_SEARCH_COLUMNS.title);
        } else {
            columns.push(EXECUTION_SEARCH_COLUMNS.key);
        }
        columns.push(EXECUTION_SEARCH_COLUMNS.status);
        columns.push(EXECUTION_SEARCH_COLUMNS.completedRatio);
        columns.push(EXECUTION_SEARCH_COLUMNS.createdDateTime);
        columns.push(EXECUTION_SEARCH_COLUMNS.lastUpdatedDateTime);
        columns.push(EXECUTION_SEARCH_COLUMNS.completedDate);
        switch (node.selectedViewId) {
            case EXECUTION_SEARCH_VIEWS.nearMe.id:
                columns.push(EXECUTION_SEARCH_COLUMNS.feature);
                break;
            default:
                break;
        }
    }
    return columns;
}
/**
 * Compute columns based on data.
 *
 * @param state
 * @param node
 * @returns {*[]}
 */
export const computeDynamicColumns = (state, node) => {

    const COLUMN = 'column_';
    const existigFieldCount = node.columns.length;

    const results = getExecutionSummaryFullByIdIfPresent(state, node.executionIds)
        .filter(a => a.deleted !== true);
    const fields = getAllFieldKeysFromExecutions(state, results, node.filterMode);
    const existingFieldsById = {};
    const existingFieldsByTitle = {};

    const columns = [];

    for (const column of node.columns) {
        if(!column.sources) {
            continue;
        }

        for (let [, src] of Object.entries(column.sources)) {
            existingFieldsById[src.questionId] = column
            existingFieldsByTitle[src.title] = column
        }
    }

    for (const column of fields) {
        const existingColumn = existingFieldsById[column.questionId] || existingFieldsByTitle[column.title];
        let addColumn = true;
        if (existingColumn) {
            if (!existingColumn.sources) {
                existingColumn.sources = {};
            }

            if(!existingColumn.dataType && column.dataType) {
                existingColumn.dataType = column.dataType;
            }

            for (let [procedureId, source] of Object.entries(column.sources)) {
                const existingSource = existingColumn.sources[procedureId];
                if (!existingSource) {
                    existingColumn.sources[procedureId] = source;
                    addColumn = false;
                    existingFieldsById[source.questionId] = existingColumn;
                    existingFieldsByTitle[source.title] = existingColumn;
                } else if (isEqual(existingSource.jsonLogic, source.jsonLogic)) {
                    addColumn = false;
                    existingFieldsById[source.questionId] = existingColumn;
                    existingFieldsByTitle[source.title] = existingColumn;
                } else {
                    // This is an edge case when 2 executions for the same template has multiple questions with the same label
                    // As we do not support multiple sources for the same template based on question label we will treat it as a new column
                }
            }
        }
        if (addColumn) {
            const field = `${COLUMN}${existigFieldCount + columns.length}`;
            column.id = column.field = field;
            columns.push(column);
            existingFieldsById[column.questionId] = column;
            existingFieldsByTitle[column.title] = column;
        }
    }
    return columns;
}

export const processSelectorLoaders = (state, node, beforeNode, updates, isListing, isQueryListing, loadFilteredUrl, useReturnFields) => {
    const question = getNodeOrNull(state, node.questionId);
    const dependencies = [];
    if (!isListing) {
        // Query Question and not Query Listing (Link Type None)
        const execution = getNodeOrNull(state, question?.rootId);
        const loaderId = NODE_IDS.ExecutionSummaryScoped(question?.rootId, useReturnFields || execution?.scopeReturnFields || [])
        const resourceLoader = getNodeOrNull(state, loaderId);
        node.isLoading = isNodeSaved(execution) && (!resourceLoader || (resourceLoader.loading && !resourceLoader.loaded));
        node.executionIds = question?.initialValue || [];
        node.total = node?.executionIds?.length;
        node.totalTitle = question?.name;
        dependencies.push({from: loaderId, to: node, properties: ['loaded', 'loading']})
        dependencies.push({from: node.questionId, to: node, properties: ['initialValue']})
        if (execution) {
            dependencies.push({from: execution, to: node, properties: ['scopeReturnFields']})
        }
    } else {
        // Listing Page or Query Listing (Link Type None)
        node.loadUrl = loadFilteredUrl;
        const resourceLoader = getNodeOrNull(state, node.loadUrl);
        node.isLoading = !resourceLoader || resourceLoader.loading;
        node.executionIds = resourceLoader?.nodeIds;
        if (!node.pivot) {
            node.total = resourceLoader?.total;
        }
        if (isQueryListing && node.viewingPageSize !== beforeNode?.viewingPageSize) {
            updates[resourceLoader.id] = {...resourceLoader, pageSize: node.viewingPageSize};
        }
        node.totalTitle = strings.dataTable.title;
        if (isQueryListing) {
            const execution = getNodeOrNull(state, question?.rootId);
            const loaderId = NODE_IDS.ExecutionSummaryScoped(question?.rootId, execution?.scopeReturnFields || [])
            node.totalTitle = question?.name;
            dependencies.push({from: loaderId, to: node, properties: ['loaded', 'loading']});
        }

        // Make recently created items appear in the list
        if (node.recentlyCreated) {
            for (let item of node.recentlyCreated) {
                if (!resourceLoader?.lastReloadTicks || resourceLoader.lastReloadTicks < item.addedTicks) {
                    let checkForIncluded = isQueryListing && node.queryFilter?.where;
                    let included = !checkForIncluded;
                    // Add if no filter, otherwise just wait for reload
                    if (checkForIncluded) {
                        included = !node.queryFilter?.where;
                    }
                    if (included) {
                        if (question && question.linkStyle === PROCEDURE_LINK_STYLE.repeatingSections.id) {
                            node.executionIds = [...(node.executionIds || []), item.id];
                        } else {
                            node.executionIds = [item.id, ...(node.executionIds || [])];
                        }
                    }
                }
                const itemNode = getNodeOrNull(state, item.id);
                const isSaved = !!itemNode?.key;
                if (isSaved && resourceLoader && question?.linkStyle !== PROCEDURE_LINK_STYLE.repeatingSections.id) {
                    node.recentlyCreated = node.recentlyCreated.filter(a => a.id !== item.id)
                    // Force reload
                    const updatedResourceLoader = {...resourceLoader, ...updates[resourceLoader.id], lastReloadTicks: null}
                    updates[updatedResourceLoader.id] = updatedResourceLoader;
                }
                dependencies.push({from: item.id, to: node, properties: ['key']})
            }
        }
    }
    return dependencies;
}

const getQuestionOptions = (question) => {
  if (question?.questionType === QUESTION_TYPES.select.id && question?.selectDataSource === SELECT_DATA_SOURCES.static.id) {
    return question.optionsParsed
  }
  return null
}

export const selectProcedureIds = (state, nodeId, isClientSideFiltering) => {

    // for query listing page, do not get procedureIds
    if(isClientSideFiltering) {
        return undefined;
    }

    const node = getNodeOrNull(state, nodeId);
    if(!node) {
        return undefined;
    }

    let rules = getActiveRulesForNode(state, nodeId).filter(a => a.linkToQuestionOn);
    let procedureIds = rules.flatMap(a => a.linkMatchProcedureIds || [])

    if(!procedureIds.length) {
        return undefined;
    }
    
    return procedureIds;
}

const getAllFieldKeysFromExecutions = (state, executions, filterMode) => {
    const KEY_FIELD_COUNT  = 5;
    if (typeof executions !== 'object' || (typeof executions === 'object' && executions.constructor !== Array)) {
        return [];
    }
    const isClientSideFiltering = filterMode === EXECUTION_FILTER_MODE.client.id;

    let fields = [];
    let fieldsById = {};
    for (let execution of executions) {
        for (let i = 1; i <= KEY_FIELD_COUNT ; i++) {
            const questionName = execution[`keyField${i}Name`];
            const questionId = execution[`keyField${i}QuestionId`];
            const existing = fieldsById[questionId];
            const question = getNodeOrNull(state, questionId)
            if (!questionName || !questionId || existing) {
                continue;
            }
            const procedureIds = selectProcedureIds(state, questionId, isClientSideFiltering);
            const useFinalValueFormatted = filterByExactUseFormatted(question);
            fields.push({
                title: questionName,
                questionId,
                orderBy: questionId,
                filterByExact: filterByExactUseFormatted(question) && !procedureIds?.length ? `${questionId}_finalValueFormatted` : `${questionId}_finalValue`,
                filterByExactMode : useFinalValueFormatted ? FILTER_BY_EXACT_MODE.words.id : FILTER_BY_EXACT_MODE.entireValue.id,
                filterBySearch: `${questionId}_finalValueFormatted`,
                sourceType: 'dynamic',
                dataType: question?.questionType,
                options: getQuestionOptions(question), // TODO: Copy from above
                sources: {
                    [execution.procedureId]: {
                        jsonLogic: {var: `execution.fields.${questionId}.valueFormatted`},
                        format: {id: 'plain'},
                        questionId,
                        title: questionName
                    }
                },
                hidden: false,
                procedureIds
            });
            fieldsById[questionId] = true;
        }
    }
    return fields;
}

function filterByExactUseFormatted(question) {
    return ([QUESTION_TYPES.richText.id, QUESTION_TYPES.geographic.id, QUESTION_TYPES.message.id, QUESTION_TYPES.signature.id, QUESTION_TYPES.message.id].includes(question?.questionType) || question?.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id);
}

export const computePivotTableColumns = (state, selectorId, procedureId, currentColumns) => {

    const selector = getNodeOrError(state, selectorId);

    const columnsVisibility = selector.columnsVisibility;

    const existingColumnsById = {};

    let columns = [];

    for (const column of currentColumns) {
        if (columnsVisibility) {
            column.hidden = columnsVisibility[column.field] === false;
        }

        columns.push(column);

        if (isStandardPivotField(column.orderBy)) {
            existingColumnsById[column.orderBy] = column;
        } else if (column.questionId) {
            existingColumnsById[column.questionId] = column;
        }
    }
    let questionsForColumns = [];
    if (procedureId) {
        questionsForColumns = getActiveDescendantsAndSelfIfPresent(state, procedureId)
            .filter((node) => node.type === NODE_TYPE_OPTIONS.ProcedureQuestion
                && node.questionType !== QUESTION_TYPES.message.id
                && node.questionType !== QUESTION_TYPES.photo.id
                && node.questionType !== QUESTION_TYPES.richText.id
                && node.questionType !== QUESTION_TYPES.geographic.id
                && node.questionType !== QUESTION_TYPES.link.id);
    }
    // Add questions if not in columns yet
    questionsForColumns.forEach((question, index) => {
        const existingQuestionColumn = existingColumnsById[question.id];
        if (existingQuestionColumn) return;
        // Questions not in existing/configured columns
        let column =
        {
            id: question.id,
            field: question.id,
            title: question.name,
            orderBy: question.id,
            filterByExact: `${question.id}_finalValue`,
            filterByExactMode : FILTER_BY_EXACT_MODE.words.id,
            filterBySearch: `${question.id}_finalValueFormatted`,
            sourceType: 'table',
            dataType: question.questionType,
            orderByDirection: 'ascending',
            format: {id: EXECUTION_SEARCH_FORMATS.plain.id},
        };
        const hasExisting = columns.length > 0;
        let hideColumn = true;
        if (columnsVisibility && hasExisting) {
            hideColumn = columnsVisibility[column.field] === false
        } else {
            if (!hasExisting) {
                hideColumn = index > 20;
            }
        }
        column = {
            ...column,
            questionId: question.id,
            title: column.title || question.name,
            options: getQuestionOptions(question),
            sources: {
                ...column.sources,
                [procedureId]: {
                    ...column.sources?.[procedureId],
                    questionId: question.id,
                    title: column.title || question.name,
                    procedureId: procedureId,
                    format: EXECUTION_SEARCH_FORMATS.plain.id,
                    returnFields: [question.id],
                }
            },
            hidden: hideColumn,
        };
        columns.push(column);
    });
    // Add standard columns if not in columns yet
    const standardColumns = [
        EXECUTION_STANDARD_PIVOT_COLUMNS.status,
        EXECUTION_STANDARD_PIVOT_COLUMNS.key,
        EXECUTION_STANDARD_PIVOT_COLUMNS.createdDateTime,
        EXECUTION_STANDARD_PIVOT_COLUMNS.id,
        EXECUTION_STANDARD_PIVOT_COLUMNS.completed,
    ];
    standardColumns.forEach((column) => {
        if (existingColumnsById[column.id]) return;
        // Pivot standard columns not in existing/configured columns
        columns.push(column);
    });
    columns = columns.map((c) => {
        if (strings.execution.columns.pivot[c.field]) {
            return {
                ...c,
                title: strings.execution.columns.pivot[c.field]
            };
        }
        return c;
    });
    return columns;
}

export const computePivotSettings = (state, nodeId, viewId) => {
    const deps = [];
    const selector = getNodeOrNull(state, nodeId);
    const pivotSettings = getActiveChildRuleByActionOrNull(state, viewId, RULE_ACTION_TYPE.pivotSettings.id);

    if (!pivotSettings || !pivotSettings.calculateValueQuery) {
        return {pivotSettings: selector?.pivotSettings, dependencies: deps};
    }

    deps.push({from: pivotSettings, to: selector, properties: ['calculateValue', 'calculateValueQuery']});

    return {pivotSettings: JSON.parse(pivotSettings.calculateValue), dependencies: deps};
}

export const computeTableColumns = (state, viewId, listingPageNode) => {
    const dependencies = [];
    let columnRules = getActiveChildRulesByAction(state, viewId, RULE_ACTION_TYPE.collectionColumn.id)
    const view = getNodeOrNull(state, viewId)
    const isClientSideFiltering = listingPageNode.filterMode === EXECUTION_FILTER_MODE.client.id;
    const isClientSideSorting = listingPageNode.sortMode === EXECUTION_FILTER_MODE.client.id;
    const procedures = [];
    const clientConfig = getNodeOrNull(state, NODE_IDS.ClientConfig)
    dependencies.push({from: NODE_IDS.ClientConfig, to: viewId, properties: ['procedures']});
    if (!listingPageNode?.questionId) {
        let procedure = getNodeOrNull(state, view?.rootId);
        if (procedure) {
            procedures.push(procedure)
        }
    } else {
        let linkRules = getActiveRulesForNode(state, listingPageNode.questionId).filter(a => a.linkToQuestionOn);
        let linkedProcedureIds = linkRules.flatMap(n => n.linkMatchProcedureIds ?? []);
        for (let procedureId of linkedProcedureIds) {
            const procedure = getNodeOrNull(state, procedureId);
            if (procedure && procedure.loadedFull) {
                procedures.push(procedure);
            } else if (clientConfig) {
                const procedureConfig = clientConfig.procedures?.[procedureId]
                if (procedureConfig) {
                    procedures.push(procedureConfig);
                }
            }
        }
        dependencies.push(...linkedProcedureIds.map(id => ({from: id, properties: ['loadedFull']})));
    }
    dependencies.push({from: viewId, properties: ['message', 'ruleIds', 'deleted']});
    const columns = [];
    const schema = getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.ExecutionRoot);
    for (let columnRule of columnRules) {
        const id = columnRule.type === NODE_TYPE_OPTIONS.ExecutionRule ? columnRule.procedureRuleId : columnRule.id;

        // Column
        let column = {
            // Using rule id as it will be unique, user could select same column twice that breaks table
            id,
            field: id,
            title: columnRule.message,
            orderBy: null,
            filterByExact: null,
            filterByExactMode : FILTER_BY_EXACT_MODE.words.id,
            filterBySearch: null,
            sourceType: 'table',
            dataType: null,
            orderByDirection: 'ascending',
            sources: {},
            hidden: false
        };

        let columnSources = getActiveChildRulesByAction(state, columnRule.id, RULE_ACTION_TYPE.collectionColumnSource.id);

        dependencies.push({from: columnRule.id, properties: ['ruleIds', 'message', 'deleted']});
        if (columnSources.length === 0) {
          continue
        }
        for (let columnSource of columnSources) {
            dependencies.push({from: columnSource.id, properties: ['calculateValueQuery', 'procedureId', 'deleted']});
            let jsonLogic = columnSource.calculateValueQuery ? JSON.parse(columnSource.calculateValueQuery) : null;
            let jsonLogicFunction = jsonLogic?.var === undefined;

            // Determine required properties
            let properties = extractJsonLogicProperties(jsonLogic);
            const orderBy = properties.length > 0 ? properties[0] : null
            const isStandard = isStandardField(orderBy);
            const isIndex = orderBy && (isStandard || procedures?.some(p => p.fieldSearchNodeIds?.includes(orderBy)))

            if (isIndex || isClientSideFiltering) {
                column.filterByExact = isStandard ? orderBy : `${orderBy}_finalValue`
                column.filterBySearch = isStandard ? orderBy : `${orderBy}_finalValueFormatted`
            }

            if (isIndex || isClientSideSorting) {
                column.orderBy = orderBy
            }

            if (orderBy) {
              // This is for the simple json logic query for the question data
              if (isStandard) {
                const standardColumn = EXECUTION_SEARCH_COLUMNS[orderBy]
                column.dataType = standardColumn?.dataType
                column.options = standardColumn?.options
              } else {
                const question = getNodeOrNull(state, orderBy)
                const procedureIds = selectProcedureIds(state, orderBy, isClientSideFiltering);
                column.dataType = question?.questionType
                column.options = getQuestionOptions(question)
                column.procedureIds = procedureIds;
                column.questionId = question?.id || orderBy;
                  // These do not have finalValue indexed, so need to use formatted value index
                  if (isIndex && filterByExactUseFormatted(question) && !procedureIds?.length)
                  {
                      column.filterByExact = isStandard ? orderBy : `${orderBy}_finalValueFormatted`
                  }
              }
            }
            let isQuestionField = properties.length > 0 && !EXECUTION_SEARCH_COLUMNS[properties[0]];
            const question = isQuestionField ? getNodeOrNull(state, properties[0]) : null;
            if (isQuestionField) {
                column.questionId = properties[0];
            }
            const useSource = {
                procedureId: columnSource.procedureId,
                jsonLogic: null,
                format: {id: EXECUTION_SEARCH_FORMATS.plain.id}
            };
            column.sources[columnSource.procedureId] = useSource;

            // Return Fields (to fetch questions)
            useSource.returnFields = properties.filter(a => schema.properties[a] === undefined);
            if (question) {
                useSource.questionId = question.id;
                useSource.title = question.name;
            }

            // Format Guesser. Later this should be set by user
            if (!jsonLogicFunction && properties.length === 1) {
                if (properties[0] === 'lastUpdatedDateTime' || properties[0] === 'createdDateTime' || properties[0] === 'completedDate') {
                    useSource.format = {id: EXECUTION_SEARCH_FORMATS.age.id};
                } else if (properties[0] === 'status') {
                    useSource.format = {id: EXECUTION_SEARCH_FORMATS.status.id};
                } else if (properties[0] === 'feature') {
                    useSource.format = {id: EXECUTION_SEARCH_FORMATS.distanceToMe.id};
                } else if (properties[0] === 'completedRatio') {
                    useSource.format = {id: EXECUTION_SEARCH_FORMATS.percentage.id};
                }
            }

            // Json Logic
            useSource.jsonLogic = convertJsonLogicForQuery(state, columnSource, columnSource.calculateValueQuery, 'summarySelf')?.result;
        }
        columns.push(column)
    }
    if (columns.length > 0) {
        columns[0].format = {id: EXECUTION_SEARCH_FORMATS.link.id};
    }
    return {columns: columns, dependencies: dependencies};
}
export const computeListingPageFilter = (state, viewId) => {
    let filterRules = getChildRulesByAction(state, viewId, RULE_ACTION_TYPE.filter.id)
        .filter(a => !a.deleted);
    if (filterRules.length === 0) {
        return null;
    }
    if (filterRules.length > 1) {
        throw new Error(`Expected 1 filter rule but received ${filterRules.length}.`);
    }
    return filterRules[0].conditionQuery;
}
export const computeListingPageOrderBy = (state, viewId) => {
    let filterRules = getChildRulesByAction(state, viewId, RULE_ACTION_TYPE.collectionOrder.id)
        .filter(a => !a.deleted);
    if (filterRules.length === 0) {
        return null;
    }
    const orderBys = [];
    for (let rule of filterRules) {
        let jsonLogic = rule.calculateValueQuery ? JSON.parse(rule.calculateValueQuery) : null;
        const properties = extractJsonLogicProperties(jsonLogic);
        orderBys.push({
            orderBy: properties.length === 1 ? properties[0] : null,
            orderByDirection: rule.orderByDirection
        })
    }
    return orderBys;
}

export const createRuleCollectionView = (nodeId, procedure, ruleSchema, attr) => {
    let parentRuleAttr = {
        alwaysOn: true,
        nodeIds: [nodeId],
        actionType: RULE_ACTION_TYPE.collectionView.id,
        messageOn: true,
        message: 'New view',
        draft: false,
        ...attr
    };
    return createChildNode(procedure, ruleSchema, parentRuleAttr);
}
export const createRuleCollectionColumn = (parentRule, procedure, ruleSchema, ruleActionType = RULE_ACTION_TYPE.collectionColumn.id, attrs = {}) => {
    let parentRuleAttr = {
        alwaysOn: true,
        nodeIds: [parentRule.id],
        actionType: ruleActionType,
        messageOn: ruleActionType === RULE_ACTION_TYPE.collectionColumn.id,
        draft: parentRule.draft,
        calculateValueOn: ruleActionType === RULE_ACTION_TYPE.collectionOrder.id || ruleActionType === RULE_ACTION_TYPE.pivotSettings.id,
        procedureOnly: parentRule.procedureOnly,
        ...attrs,
    };
    if (ruleActionType === RULE_ACTION_TYPE.collectionOrder.id) {
        parentRuleAttr.orderByDirection = EXECUTION_ORDER_BY_DIRECTION.ascending.id
    }
    return createChildNode(procedure, ruleSchema, parentRuleAttr);
}

export const createChildRuleAction = (parentRule, procedureNode, schema, ruleActionType, attrs = {}) => {
    const ruleAttr = {
        alwaysOn: true,
        nodeIds: [parentRule.id],
        actionType: ruleActionType,
        draft: procedureNode.draft,
        ...attrs,
    }

    return createChildNode(procedureNode, schema, ruleAttr);
}

export const createRuleCollectionColumnSource = (parentRule, procedure, ruleSchema, attr) => {
    let parentRuleAttr = {
        alwaysOn: true,
        nodeIds: [parentRule.id],
        actionType: RULE_ACTION_TYPE.collectionColumnSource.id,
        draft: parentRule.draft,
        calculateValueOn: true,
        procedureOnly: parentRule.procedureOnly,
        ...attr
    };
    return createChildNode(procedure, ruleSchema, parentRuleAttr);
}
export const createRule = (parentRule, procedure, ruleSchema, attr) => {
    let parentRuleAttr = {
        alwaysOn: true,
        draft: parentRule.draft,
        nodeIds: [parentRule.id],
        actionType: RULE_ACTION_TYPE.block.id,
        ...attr
    };
    return createChildNode(procedure, ruleSchema, parentRuleAttr);
}
export const SecurityChildRules = () => {
    const P = GRANT_DENY_PERMISSIONS;
    return [

        // Field User
        {
            actionType: RULE_ACTION_TYPE.grant.id,
            permissions: [P.create, P.edit, P.complete, P.list, P.search, P.assign, P.link, P.delete].map(a => a.id),
            calculateValueOn: true,
            calculateValueQuery: JSON.stringify([ROLES.ITPFieldUser].map(a => a.id)),
            calculateValueHumanStored: [ROLES.ITPFieldUser].map(a => a.name).join(', '),
            calculateValueHuman: [ROLES.ITPFieldUser].map(a => a.name).join(', '),
            procedureOnly: true,
        },
        // Template Designer
        {
            actionType: RULE_ACTION_TYPE.grant.id,
            permissions: [P.design, P.publish].map(a => a.id),
            calculateValueOn: true,
            calculateValueQuery: JSON.stringify([ROLES.ITPTemplateDesigner].map(a => a.id)),
            calculateValueHumanStored: [ROLES.ITPTemplateDesigner].map(a => a.name).join(', '),
            calculateValueHuman: [ROLES.ITPTemplateDesigner].map(a => a.name).join(', '),
            procedureOnly: true,
        },
        // ITP Administrator
        {
            actionType: RULE_ACTION_TYPE.grant.id,
            permissions: [P.all].map(a => a.id),
            calculateValueOn: true,
            calculateValueQuery: JSON.stringify([ROLES.ITPAdministrator].map(a => a.id)),
            calculateValueHumanStored: [ROLES.ITPAdministrator].map(a => a.name).join(', '),
            calculateValueHuman: [ROLES.ITPAdministrator].map(a => a.name).join(', '),
            procedureOnly: true,
        }
    ];
}
export const createNewSecurityRules = (procedure, ruleSchema) => {
    let newRule = createRule(procedure, procedure, ruleSchema, {actionType: RULE_ACTION_TYPE.security.id})
    let newRules = [newRule];
    for (let childRule of SecurityChildRules() || []) {
        let newChildRule = createRule(newRule, procedure, ruleSchema, childRule)
        newRules.push(newChildRule)
    }
    return newRules;
}

export const pruneDeleted = (state, procedureId) => {

    let updatedNodes = [];
    let updatedNodeById = {};
    function proneNode(nodeId, property) {
        let revisedNode = updatedNodeById[nodeId] || {...getNodeOrError(state, nodeId)};
        updatedNodeById[revisedNode.id] = revisedNode;
        if (revisedNode[property]) {
            let listBefore = revisedNode[property];
            revisedNode[property] = [];
            for (let id of listBefore) {
                let childNode = getNodeOrNull(state, id);
                if (!childNode?.deleted) {
                    revisedNode[property].push(id);
                    updatedNodes.push(proneNode(id, property))
                }
            }
        }
        return revisedNode;
    }

    let node = proneNode(procedureId, 'children');
    node.pruneOn = true;
    updatedNodes.push(node);
    proneNode(procedureId, 'rules');
    return updatedNodes;
}

export const computeNodeNumber = (state, node, update) => {
    if (!node.editOn && node.number) {
        return;
    }
    const nodes = getActiveChildrenDescendantsAndSelf(state, node.id);
    let number = 1;
    for (node of nodes) {
        if (node.number !== number) {
            let cloned = update[node.id] || {...node};
            cloned.number = number;
            update[cloned.id] = cloned;
        }
        number++;
    }
}
export const reviseNodeNumber = (state, beforeState, node, rootId, update) => {
    let root = getNodeOrError(state, rootId);
    if (!root.editOn) {
        return;
    }
    let beforeNode = getNodeOrNull(beforeState, node.id);
    if (!beforeNode && !node.number) {
        return;
    }
    if (isEqual(beforeNode?.children, node.children) && beforeNode?.deleted === node.deleted) {
        return;
    }
    const nodes = getActiveChildrenDescendantsAndSelf(state, root.id);
    let number = 1;
    for (let checkNode of nodes) {
        if (checkNode.number !== number) {
            let cloned = update[checkNode.id] || {...checkNode};
            cloned.number = number;
            update[cloned.id] = cloned;
        }
        number++;
    }
}
export const getJsonLogicReferences = (state, nodeId) => {
    let rootNode = getRootNodeOrError(state, nodeId)
    let childrenNodes = getActiveChildrenDescendantsAndSelf(state, rootNode.id);
    let typeToField = {
        [NODE_TYPE_OPTIONS.ProcedureRoot]: '_id',
        [NODE_TYPE_OPTIONS.ProcedureStep]: '_completed',
        [NODE_TYPE_OPTIONS.ProcedureTask]: '_completed',
        [NODE_TYPE_OPTIONS.ProcedureQuestion]: '_finalValue',
    };
    let typeToName = {
      [NODE_TYPE_OPTIONS.ProcedureRoot]: 'Item',
      [NODE_TYPE_OPTIONS.ProcedureStep]: 'Step',
      [NODE_TYPE_OPTIONS.ProcedureTask]: 'Task',
      [NODE_TYPE_OPTIONS.ProcedureQuestion]: 'Question',
  };

    const references = [];
    for (let x = 0; x < childrenNodes.length; x++) {
        const item = childrenNodes[x]
        const i = x + 1;
        references.push({
            shortName: '' + i,
            display: `${item.name} (${typeToName[item.type]})`,
            path: item.id + typeToField[item.type]
        });
        references.push({shortName: i + '.Name', display: 'Name', path: item.id + '_name'});
        references.push({
            shortName: i + '.Completed',
            display: 'Completed',
            path: item.id + '_completed'
        });
        if (item.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
            references.push({shortName: i + '.Key', display: 'Key', path: rootNode.id + '_key'});
            references.push({shortName: i + '.Title', display: 'Title', path: rootNode.id + '_title'});
        } else if (item.type === NODE_TYPE_OPTIONS.ProcedureQuestion) {
            // For now not supporting resolvedPhotoIds
            references.push({
                shortName: i + '.Formatted',
                display: 'Attachments',
                path: item.id + '_finalValueFormatted'
            });
            references.push({
                shortName: i + '.Attachments',
                display: 'Attachments',
                path: item.id + '_initialPhotoIds'
            });
            references.push({
                shortName: i + '.Notes',
                display: 'Notes',
                path: item.id + '_initialComment'
            });
            if (item.questionType === QUESTION_TYPES.geographic.id) {
                references.push({
                    shortName: i + '.Length',
                    display: 'Length in meters',
                    path: item.id + '_finalValue.properties.length'
                });
            }
        }
    }
    const rules = getNodesOrError(state, rootNode.rules, rootNode);
    for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (!rule.deleted && rule.createExecutionOn) {
            references.push({
                shortName: 'R' + (i + 1),
                display: `${rule.name} (Rule)`,
                path: rule.id + '_finalValue'
            });
        }
    }
    references.push({
        shortName: 'User.Id',
        display: 'User Id',
        path: 'user_executionId'
    });
    references.push({
        shortName: 'User.Email',
        display: 'User Email',
        path: 'user_email'
    });
    references.push({
        shortName: 'User.Name',
        display: 'User Name',
        path: 'user_name'
    });
    references.push({
        shortName: 'User.Roles',
        display: 'User Roles',
        path: 'user_roles'
    });
    references.push({
        shortName: 'User.Groups',
        display: 'User Groups',
        path: 'user_groups'
    });
    references.push({
        shortName: 'Location',
        display: 'Location',
        path: 'location_feature'
    });
    references.push({
        shortName: 'Location.Latitude',
        display: 'Location Latitude',
        path: 'location_position_latitude'
    });
    references.push({
        shortName: 'Location.Longitude',
        display: 'Location Longitude',
        path: 'location_position_longitude'
    });
    references.push({
        shortName: 'Item',
        display: 'Item',
        path: ''
    });
    return {references: references};
}


export const computeHasLocation = (state, procedure) => {
    const descendants = getActiveDescendants(state, procedure.id);
    const geoGraphicQuestion = descendants.find(d => d.type === NODE_TYPE_OPTIONS.ProcedureQuestion && d.questionType === QUESTION_TYPES.geographic.id && !d.deleted);
    procedure.hasLocationField = geoGraphicQuestion != null;
}

export const processJsonLogicFormula = (state, node, editOn, field, recalculate) => {
    let human = field + 'Human';
    let query = field + 'Query';
    let stored = field + 'HumanStored';
    let error = field + 'Error';
    let isFocused = state.focusedNode && state.focusedNode.id === node.id && state.focusedNode.propertyName === human;
    if (!editOn) {
        // Nothing to do
    } else if (isFocused || recalculate || (node[human] && (node[query] == null))) {
        // Convert from human back to jsonlogic
        let schema = getJsonLogicReferences(state, node.id)
        let jsonLogicResult = convertFormulaToJsonLogic(node[human], schema);
        if (jsonLogicResult.error) {
            node[error] = jsonLogicResult.error || null;
            node[query] = null;
        } else if (query == null) {
            node[query] = null;
            node[error] = null;
        } else {
            node[query] = JSON.stringify(jsonLogicResult.jsonLogic);
            node[error] = null;
        }
        if (node[human] === '') {
            node[human] = null;
        }
        node[stored] = jsonLogicResult.storeExpression;
    } else {
        // Convert from stored back to human
        try {
            let schema = getJsonLogicReferences(state, node.id)
            let humanStored = node[stored];
            let result = rewriteStoredExpressionToHuman(humanStored, schema);
            node[human] = result.human === '' ? null : result.human;
            node[error] = result.errors || null;
        } catch (error) {
            node[error] = error.message || JSON.stringify(error) || null;
        }
    }
}

export const makeServerSideRule = (state, procedure, procedureRule1) => {
    return createChildNode(procedure, state.schema.ProcedureRule, {
        nodeIds: [procedureRule1.id],
        alwaysOn: true,
        actionType: RULE_ACTION_TYPE.computeOnServer.id
    });
}
export const makeClientSideRule = (state, procedure, procedureRule1) => {
    return createChildNode(procedure, state.schema.ProcedureRule, {
        nodeIds: [procedureRule1.id],
        alwaysOn: true,
        actionType: RULE_ACTION_TYPE.computeOnClient.id
    });
}

export const createProcedureNode = (schema, attributes, procedureType) => {
    const node = createNode(schema, attributes);
    if(node.type === NODE_TYPE_OPTIONS.ProcedureQuestion && procedureType !== PROCEDURE_TYPES.itp.id) {
        node.photo = CONDITIONAL_VALUES.none.id;
        node.comment = CONDITIONAL_VALUES.none.id;
    }
    return node;
}

export const DynamicLabelRules = (completeStyle) => {

    const isForm = completeStyle === COMPLETE_ACTION_STYLES.form.id;
    const executionString = strings.execution.show[completeStyle];

    const completeLabelSet = [
        // Complete Button Label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.completeButton,
            format: COMPLETE_LABELS_FORMAT.completeButton.id
        },
        // Final Complete Button Label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.submitCompleted,
            format: COMPLETE_LABELS_FORMAT.submitCompleted.id
        },
        // Revert Button Label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.undoCompleteButton,
            format: COMPLETE_LABELS_FORMAT.undoCompleteButton.id
        },
        // Completed by prefix
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.nodeCompletedPrefix,
            format: COMPLETE_LABELS_FORMAT.nodeCompletedPrefix.id
        },
        // Not Completed and Readonly label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.notCompleted,
            format: COMPLETE_LABELS_FORMAT.notCompleted.id
        },
        // Next Button Label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman:  executionString.nextCompleted,
            format: COMPLETE_LABELS_FORMAT.nextCompleted.id
        },
        // Previous Button Label
        {
            actionType: RULE_ACTION_TYPE.label.id,
            calculateValueOn: true,
            calculateValueHuman: executionString.previousCompleted,
            format: COMPLETE_LABELS_FORMAT.previousCompleted.id
        }
    ];

    if (isForm) {
        completeLabelSet.push({
            actionType: RULE_ACTION_TYPE.navigateNextOnComplete.id,
            alwaysOn: true,
        })
    }

    return completeLabelSet;
}