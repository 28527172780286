import {useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {createNode} from '../factory/graphFactory'
import {putNode, putNodeProperty, putNodesProperty, reportNodeEvent} from '../actions'
import {
    buildLookup,
    getActiveDescendants,
    getActiveDescendantsAndSelf,
    getDescendantsAndSelfIfPresent,
    getDirtyNodes,
    getNodesActiveDescendants,
    getNodesById,
    getNodeSchemaOrError,
    getNodeSchemaOrNull,
    getNodesIfPresent,
    getSchema,
    isNodeDirty,
    isRootNodeDirty
} from '../selectors/graphSelectors'
import {DIAGNOSTIC_MODES, NODE_IDS} from "../reducers/graphReducer";
import {
    getActiveChildRuleByActionOrNull,
    getActiveChildRulesByAction,
    getActiveChildRulesByActionIfPresent,
    getActiveRulesByActionForNode,
    getChildRuleByActionAndFormatOrNull,
    getChildRuleByActionOrNull,
    getChildRulesByAction,
    getEnabledChildRulesForNodeByActionOrNull,
    getNodesRulesByAction,
    getProcedureChildRulesByAction,
    getRuleByActionOrNull,
    getRulesByActionIfPresent,
    getRulesForNode,
    getRulesForNodeByActionIfPresent,
    getRulesWithoutNode
} from "../selectors/ruleSelectors";
import {getJsonLogicReferences} from "../factory/procedureFactory";
import {
    getExecutionSummaryFullByIdIfPresent,
    getLinkedChildren,
    hasExecutionPermission,
    hasOfflineEnabled
} from "../selectors/executionSelectors";
import {isSavePendingOnExecution} from "../factory/executionFactory";

export const usePendingStoreDbNodeCount = () => {
    return useSelector(state => state?.graph?.pendingStoreDbNodeCount)
}
export const useSaveRunning = () => {
    return useSelector(state => state?.graph?.saveRunning)
}
export const useInternetAvailable = () => {
    return useSelector(state => state?.graph?.internetAvailable)
}
export const useNodeOrNull = (nodeId) => {
    return useSelector(state => state?.graph?.nodes?.[nodeId])
}
export const useDirtyOrNull = (nodeId) => {
    return useSelector(state => state?.graph?.dirtyNodes?.[nodeId])
}
export const useStoreDbFailed = (nodeId) => {
    return useSelector(state => !!state?.graph?.storedDbErrorNodeIds?.[nodeId])
}
export const useNodePropertyOrNull = (nodeId, func) => {
    return useSelector(state => func(state?.graph?.nodes?.[nodeId]))
}
export const useNodesById = (nodeIds) => {
    return useSelector(state => getNodesById(state, nodeIds), shallowEqual)
}
export const useBuildLookup = (nodeIds) => {
    return useSelector(state => buildLookup(state, nodeIds))
}
export const useNodeOrError = (nodeId) => {
    const node = useNodeOrNull(nodeId)
    if (!node) {
        throw new Error(`Node with id [${nodeId}] does not exist.`);
    }
    return node
}
export const useNodeSchemaOrNull = (nodeType) => {
  return useSelector(state => getNodeSchemaOrNull(state, nodeType))
}
export const useNodeSchemaOrError = (nodeType) => {
  return useSelector(state => getNodeSchemaOrError(state, nodeType))
}

export const useSchema = () => {
    return useSelector(state => getSchema(state));
}

export const useGetOrCreateNode = (nodeId, nodeType, defaults = {}, createOn = true) => {
    const node = useNodeOrNull(nodeId)
    const schema = useNodeSchemaOrError(nodeType)
    const dispatch = useDispatch()

    const defaultNode = useMemo(() => ({
        id: nodeId,
        ...defaults
    }), []) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
        if (!node && createOn) {
            const newNode = createNode(schema, defaultNode)
            dispatch(putNodeProperty(newNode))
        }
    }, [node, createOn, schema, dispatch, nodeId]) /* eslint-disable-line react-hooks/exhaustive-deps */

    return node || defaultNode
}

export const useCreateNode = (nodeType, attrs = {}, createOn = false) => {
    const [nodeId, setNodeId] = useState(null);
    const schema = useNodeSchemaOrError(nodeType);
    const dispatch = useDispatch();

    const node = useNodeOrNull(nodeId);

    useEffect(() => {
        if (!node && createOn) {
            const newNode = createNode(schema, attrs);
            dispatch(putNodeProperty(newNode));
            setNodeId(newNode.id);
        }
    }, [node, schema, createOn, dispatch])

    return node;
}
export const useReportEventCallback = () => {
    const dispatch = useDispatch();

    return useCallback((id, eventName, eventProperties) => {
        return dispatch(reportNodeEvent(id, eventName, eventProperties));
    }, [dispatch]);
}
export const useActiveDescendants = (nodeId) => {
    return useSelector(state => getActiveDescendants(state, nodeId), shallowEqual)
};
export const useNodesActiveDescendants = (nodeIds) => {
    return useSelector(state => getNodesActiveDescendants(state, nodeIds), shallowEqual)
};
export const useDescendantsAndSelfIfPresent = (nodeId) => {
    return useSelector(state => getDescendantsAndSelfIfPresent(state, nodeId), shallowEqual)
};
export const useActiveDescendantsAndSelf = (nodeId) => {
    return useSelector(state => getActiveDescendantsAndSelf(state, nodeId), shallowEqual)
};
export const useJsonLogicReferences = (nodeId) => {
    return useSelector(state => getJsonLogicReferences(state, nodeId), shallowEqual)
};
export const useChildRulesByAction = (parentRuleId, actionType) => {
    return useSelector(state => getChildRulesByAction(state, parentRuleId, actionType), shallowEqual)
}
export const useActiveChildRulesByAction = (parentRuleId, actionType) => {
    return useSelector(state => getActiveChildRulesByAction(state, parentRuleId, actionType), shallowEqual)
}
export const useEnabledChildRulesForNodeByActionOrNull = (parentRuleId, actionType) => {
    return useSelector(state => getEnabledChildRulesForNodeByActionOrNull(state, parentRuleId, actionType), shallowEqual)
}

export const useActiveChildRulesByActionIfPresent = (parentRuleId, actionType) => {
    return useSelector(state => getActiveChildRulesByActionIfPresent(state, parentRuleId, actionType), shallowEqual)
}
export const useRuleForNodeByActionOrNull = (parentRuleId, actionType, strictEqual) => {
    return useSelector(state => getChildRuleByActionOrNull(state, parentRuleId, actionType), strictEqual ? shallowEqual : undefined);
}
export const useChildRuleByActionOrNull = (parentRuleId, actionType) => {
    return useSelector(state => getChildRuleByActionOrNull(state, parentRuleId, actionType))
}
export const useChildRuleByActionAndFormatOrNull = (parentRuleId, actionType, format) => {
    return useSelector(state => getChildRuleByActionAndFormatOrNull(state, parentRuleId, actionType, format))
}
export const useActiveChildRuleByActionOrNull = (parentRuleId, actionType) => {
    return useSelector(state => getActiveChildRuleByActionOrNull(state, parentRuleId, actionType))
}

export const useRulesForNodeByActionIfPresent = (procedureId, actionType) => {
    return useSelector(state => getRulesForNodeByActionIfPresent(state, procedureId, actionType), shallowEqual)
}

export const useRulesByActionIfPresent = (procedureId, actionType, eqFn) => {
    return useSelector(state => getRulesByActionIfPresent(state, procedureId, actionType), eqFn ?? shallowEqual)
}
export const useRuleByActionOrNull = (procedureId, actionType, filter) => {
    return useSelector(state => getRuleByActionOrNull(state, procedureId, actionType, filter), shallowEqual)
}
export const useRulesForNode = (nodeId) => {
    return useSelector(state => getRulesForNode(state, nodeId), shallowEqual)
}
export const useRulesMissingNode = (nodeId) => {
    return useSelector(state => getRulesWithoutNode(state, nodeId), shallowEqual)
}
export const useActiveRulesByActionForNode = (nodeId, actionType) => {
    return useSelector(state => getActiveRulesByActionForNode(state, nodeId, actionType), shallowEqual)
}
export const useNodesIfPresent = (nodeIds) => {
    return useSelector(state => getNodesIfPresent(state, nodeIds), shallowEqual)
}
export const usePermissibleNodesIfPresent = (nodeIds, permission) => {
    return useSelector(state => getNodesIfPresent(state, nodeIds).filter(n => hasExecutionPermission(state, n.id, permission)), shallowEqual)
}
export const useDirtyNodes = () => {
    return useSelector(state => getDirtyNodes(state), shallowEqual)
}
export const usePendingUserSaveNodeIds = () => {
    return useSelector(state => state?.graph?.pendingUserSaveNodeIds)
}
export const useSavingRootIds = () => {
    return useSelector(state => state?.graph?.savingRootIds)
}
export const useIsSavePending = (nodeId) => {
    return useSelector(state => isSavePendingOnExecution(state, nodeId))
}
export const useCallbackPatchNode = (patch, confirmation) => {
    const dispatch = useDispatch()
    return useCallback((arg) => {
        if (!confirmation || window.confirm(confirmation)) {
            dispatch(putNodeProperty(patch || arg))
        }
    }, [dispatch, patch, confirmation]);
}

export const useCallbackPatchNodes = (patch, confirmation) => {
    const dispatch = useDispatch()
    return useCallback((arg) => {
        if (!confirmation || window.confirm(confirmation)) {
            dispatch(putNodesProperty(patch || arg))
        }
    }, [dispatch, patch, confirmation]);
}

export const useDiagnosticsOn = () => {
    const diagnosticMode = useNodePropertyOrNull(NODE_IDS.UserDevice, a => a.diagnosticMode)
    return diagnosticMode === DIAGNOSTIC_MODES.full.id;
}

export const useIsNodeDirty = (nodeId) => {
    return useSelector(state => isNodeDirty(state, nodeId))
}

export const useIsRootNodeDirty = (nodeId) => {
    return useSelector(state => isRootNodeDirty(state, nodeId))
}

export const usePutNode = () => {
    const dispatch = useDispatch()
    return useCallback((node) => {
        dispatch(putNode(node))
    }, [dispatch]);
}

export const usePutNodesProperty = () => {
    const dispatch = useDispatch();
    return useCallback((nodes) => {
        dispatch(putNodesProperty(nodes));
    }, [dispatch]);
}
export const useChildRulesByActionOrNull = (procedureId, actionType) => {
    return useSelector(state => getProcedureChildRulesByAction(state, procedureId, actionType))
}

export const useGetExecutionSummaryFullByIdIfPresent = (executionIds) => {
    return useSelector(state => getExecutionSummaryFullByIdIfPresent(state, executionIds))
};

export const useGetLinkedChildren = (executionId) => {
    return useSelector(state => getLinkedChildren(state, executionId))
};

export const useGetNodesRulesByAction = (nodeIds, actionType) => {
    return useSelector(state => getNodesRulesByAction(state, nodeIds, actionType));
}

export const useOfflineEnabled = () => {
    return useSelector(state => hasOfflineEnabled(state))
};