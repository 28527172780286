import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import '../../style/alert.css';
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../actions";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/InfoRounded';
import MailIcon from '@mui/icons-material/MailOutlineRounded';
import {
    DATE_FORMAT_STORED,
    DATETIME_FORMAT_STORED,
    DIAGNOSTIC_MODES,
    NODE_IDS,
    QUESTION_TYPES,
    SELECT_DATA_SOURCES,
    SELECT_RENDER_MODES,
    TIME_FORMAT_STORED
} from "../../reducers/graphReducer";
import {hasItems, hasValue, isNotBlank} from "../../util/util";
import JSONPretty from "react-json-pretty";
import 'date-fns';
import moment from "moment";
import draftToHtml from "draftjs-to-html";
import {strings} from "../components/SopLocalizedStrings";
import HelpPointMarker from "../../manuals/layouts/HelpPointMarker";
import HelpPointInline from "../../manuals/layouts/HelpPointInline";
import RenderDraftJs, {customImageEntityTransform} from "../components/RenderDraftJs";
import {Error, ErrorBoundary, ScreenReaderText, withMobile, reportUserError} from "tbf-react-library";
import ExecutionQuestionSelectExecution from "./question/input/ExecutionQuestionSelectExecution";
import ExecutionQuestionSelectRadio from "./question/input/ExecutionQuestionSelectRadio";
import ExecutionQuestionSelectCheckbox from "./question/input/ExecutionQuestionSelectCheckbox";
import ExecutionQuestionSelectDropDown from "./question/input/ExecutionQuestionSelectDropDown";
import ExecutionQuestionSelectChip from "./question/input/ExecutionQuestionSelectChip";
import ExecutionQuestionDateTime from "./question/input/ExecutionQuestionDateTime";
import ExecutionQuestionDate from "./question/input/ExecutionQuestionDate";
import ExecutionQuestionTime from "./question/input/ExecutionQuestionTime";
import MessageType from "../components/MessageType";
import FieldIncompleteIndicator from "../components/FieldIncompleteIndicator";
import ExecutionQuestionNote from "./question/input/ExecutionQuestionNote";
import ExecutionQuestionUnknown from "./question/input/ExecutionQuestionUnknown";
import AttachmentIcon from "@mui/icons-material/AttachFileRounded";
import PhotoCameraIcon from '@mui/icons-material/CameraAltRounded';
import Badge from "@mui/material/Badge";
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import ExecutionQuestionInputBase from "./question/input/ExecutionQuestionInputBase";
import ExecutionTakePhoto from "./question/attachment/ExecutionTakePhoto";
import ExecutionPhotoList from "./question/attachment/ExecutionPhotoList";
import ExecutionQuestionMessages from "./question/message/ExecutionQuestionMessages";
import ExecutionQuestionIssueContainer from "./question/message/ExecutionQuestionIssueContainer";
import ExecutionQuestionGeographic from "./question/input/ExecutionQuestionGeographic";
import ExecutionQuestionSignature from "./question/input/ExecutionQuestionSignature";
import SopRichTextEditor from "./LazySopRichTextEditor";
import ExecutionQuestionGeographicMap from "./question/input/ExecutionQuestionGeographicMap";
import ExecutionQuestionNumber from "./question/input/ExecutionQuestionNumber";
import {questionHasInvalidReason} from "../../selectors/executionSelectors";
import ExecutionQuestionText from "./question/input/ExecutionQuestionText";
import ExecutionAnsweredBy from './ExecutionAnsweredBy';
import HighlightNode from './troubleshoot/HighlightNode';
import ExecutionQuestionPhoto from "./question/input/ExecutionQuestionPhoto";
import ExecutionQuestionPhoneNumber from './question/input/ExecutionQuestionPhoneNumber';
import cn from 'classnames';
import ExecutionQuestionJson from "./question/input/ExecutionQuestionJson";
import {RICHTEXT_MAX_SIZE} from "./SopRichTextEditor";

const AddAttachmentButton = ({executionQuestionNode, field, photoDisabled, classes}) => {
    const photoCount = executionQuestionNode[field + 'PhotoIds']?.length ?? 0;
    const hasPhotos = photoCount > 0;
    const photosEnabled = executionQuestionNode[field + 'PhotoIdsEnabled'];
    const photosRequired = executionQuestionNode[field + 'PhotoIdsRequired'];
    const photoInputId = executionQuestionNode.id + '-' + field;

    const {attachmentIcon, attachmentTitle} = React.useMemo(() => {
        let attachmentIcon;
        let attachmentTitle;

        if (photosRequired && !hasPhotos && !photoDisabled) {
            if (executionQuestionNode.attachmentType === "file") {
                attachmentIcon = <AttachmentIcon className={classes.iconButtonRequired}/>;
                attachmentTitle = strings.execution.show.addAttachmentTitle;
            } else {
                attachmentIcon = <PhotoCameraIcon className={classes.iconButtonRequired}/>;
                attachmentTitle = strings.execution.show.takePhotoTitle;
            }
        } else {
            if (executionQuestionNode.attachmentType === "file") {
                attachmentIcon = <AttachmentIcon/>;
                attachmentTitle = strings.execution.show.addAttachmentTitle;
            } else {
                attachmentIcon = <PhotoCameraIcon/>;
                attachmentTitle = strings.execution.show.takePhotoTitle;
            }
        }

        return {attachmentIcon, attachmentTitle};
    }, [photosRequired, hasPhotos, photoDisabled, executionQuestionNode.attachmentType])

    const handleAddPhoto = () => {
        document.getElementById(photoInputId).click()
    }

    const stopPropagation = (e) => e.stopPropagation();

    return <IconButton
        color={'secondary'}
        title={attachmentTitle}
        className={cn(classes.photoIcon, {[classes.hide]: !photosEnabled})}
        hidden={!photosEnabled}
        disabled={photoDisabled}
        onClick={handleAddPhoto}
        size="large">
        {
            hasPhotos &&
            <Badge badgeContent={photoCount} color={"primary"}>
                <label onClick={stopPropagation} htmlFor={photoInputId} className={classes.photoLabel}>
                    {attachmentIcon}
                </label>
            </Badge>
        }
        {
            !hasPhotos &&
            <React.Fragment>
                <label onClick={stopPropagation} htmlFor={photoInputId} className={classes.photoLabel}>
                    {attachmentIcon}
                </label>
            </React.Fragment>
        }
        <ScreenReaderText>{attachmentTitle}</ScreenReaderText>
    </IconButton>
}

class ExecutionShowQuestionAudit extends ExecutionQuestionInputBase {

    constructor(props) {
        super(props);
        this.state = {
            showJson: false
        };
        this.toggleShowJson = this.toggleShowJson.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.renderText = this.renderText.bind(this);
        this.renderNumber = this.renderNumber.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.renderEmail = this.renderEmail.bind(this);
        this.renderDate = this.renderDate.bind(this);
        this.renderDateTime = this.renderDateTime.bind(this);
        this.renderTime = this.renderTime.bind(this);
        this.renderJson = this.renderJson.bind(this);
    }

    runAfterRender = null;

    componentDidUpdate() {
        if (this.runAfterRender !== null) {
            this.runAfterRender();
            this.runAfterRender = null;
        }
    }

    toggleShowJson() {
        this.setState(state => ({showJson: !state.showJson}));
    }

    handleChange = (field) => (e) => {
        this.updateNodeProperty(field, e.target.value, field);
    };

    handleToggleUnknown = (field) => () => {
        let {executionQuestionNode} = this.props;
        this.updateNodeProperty(field + 'ValueUnknown', !executionQuestionNode[field + 'ValueUnknown'], field + 'Value');
    };

    handleResolution = (resolution) => () => {
        this.updateNodeProperty('issueResolution', resolution);
    };

    renderText = (value, valueUnknown, field) => {

        let {executionQuestionNode, valueDisabled} = this.props;
        return (<ExecutionQuestionText
            disabled={valueDisabled || valueUnknown}
            readOnly={valueDisabled || valueUnknown}
            field={field}
            value={value}
            questionId={executionQuestionNode.id}
            node={executionQuestionNode}
        />)
    };

    renderRichText = (value, valueUnknown, field) => {
        const {valueDisabled, executionId, questionId} = this.props;

        if (valueDisabled) {
            return <RenderDraftJs contentState={value}/>;
        }
        return (
            <ErrorBoundary>
                <SopRichTextEditor
                    id={questionId}
                    nodeId={questionId}
                    executionId={executionId}
                    isQuestionInput={true}
                    value={value}
                    onFocus={this.handleFocusElement}
                    onBlur={this.handleBlurElement}
                    onContentStateChange={(contentState) => {
                        let html = draftToHtml(contentState, {}, false, customImageEntityTransform);
                        let htmlText = html.replace(/(<p>|<\/p>|\s|)/gm, '');

                        const encoder = new TextEncoder();
                        const rawString = JSON.stringify(contentState);
                        const encodedString = encoder.encode(rawString);

                        if (!htmlText) {
                            this.updateNodeProperty(field, null, field);
                        } else if (encodedString.length > RICHTEXT_MAX_SIZE) {
                            reportUserError("Rich text field exceeds maximum size of 10MB");
                        }else {
                            this.updateNodeProperty(field, rawString, field);
                        }
                    }}/>
            </ErrorBoundary>
        );
    };

    renderEmail = (value, valueUnknown, field) => {
        const {executionQuestionNode} = this.props;
        const endAdornment = executionQuestionNode.formatDisplay ?
            <InputAdornment position="end">{executionQuestionNode.formatDisplay}</InputAdornment> : '';
        const standardProperties = this.renderInputStandardProperties(value, valueUnknown, field);
        return (
            <Input
                type={'email'}
                fullWidth={true}
                startAdornment={<MailIcon/>}
                endAdornment={endAdornment}
                onChange={this.handleChange(field)}
                {...standardProperties}
            />);
    };

    renderPhoto = (value, valueUnknown, field) => {
        let {executionQuestionNode, disabled} = this.props;
        return <React.Fragment>
            <ExecutionQuestionPhoto executionQuestion={executionQuestionNode} field={field} disabled={disabled}/>
        </React.Fragment>
    };

    renderNumber = (value, valueUnknown, field, valueFormatted) => {
        let {executionQuestionNode, valueDisabled} = this.props;
        return <ExecutionQuestionNumber
            disabled={valueDisabled || valueUnknown}
            field={field}
            value={value}
            valueFormatted={valueFormatted}
            questionId={executionQuestionNode.id}/>
    };

    renderPhoneNumber = (value, valueUnknown, field) => {
        let {executionQuestionNode, valueDisabled} = this.props;
        return <ExecutionQuestionPhoneNumber
            value={value}
            valueUnknown={valueUnknown}
            disabled={valueDisabled}
            executionQuestion={executionQuestionNode}
            field={field}
        />;
    };

    renderSelect = (value, valueUnknown, field) => {
        let {valueDisabled, questionId, executionQuestionNode, executionId} = this.props;
        let disabled = valueDisabled || valueUnknown;
        let renderMode = executionQuestionNode.selectRenderMode;
        if (!renderMode) {
            renderMode = executionQuestionNode.selectMany ? SELECT_RENDER_MODES.checkbox.id : SELECT_RENDER_MODES.radio.id;
        }
        switch (renderMode) {
            case SELECT_RENDER_MODES.autocomplete.id:
                if (executionQuestionNode.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id) {
                    return <ExecutionQuestionSelectExecution questionId={questionId} field={field} disabled={disabled}
                                                             executionId={executionId}/>
                }
                return <ExecutionQuestionSelectDropDown questionId={questionId} field={field} disabled={disabled}/>
            case SELECT_RENDER_MODES.radio.id:
                return <ExecutionQuestionSelectRadio questionId={questionId} field={field} disabled={disabled}/>
            case SELECT_RENDER_MODES.checkbox.id:
                return <ExecutionQuestionSelectCheckbox questionId={questionId} field={field} disabled={disabled}/>
            case SELECT_RENDER_MODES.chip.id:
                return <ExecutionQuestionSelectChip questionId={questionId} field={field} disabled={disabled}/>
            default:
                return <Error>Unknown render mode {executionQuestionNode.selectRenderMode}</Error>
        }
    };

    renderMessage = () => {
        let {executionQuestionNode, classes} = this.props;
        return (
            <div className={classes.fullWidth}>
                <MessageType contentState={executionQuestionNode.initialValue}
                             messageType={executionQuestionNode.messageType} customClass={classes.questionTypeMessage}/>
            </div>
        );
    };

    renderCommentIcon = (field) => {
        let {classes, executionQuestionNode, disabled} = this.props;
        let commentRequired = executionQuestionNode[field + 'CommentEnabled'];
        let hasComment = isNotBlank(executionQuestionNode[field + 'Comment']);
        let commentRequested = executionQuestionNode[field + 'CommentRequested'];
        let commentIcon;

        if (hasComment || !commentRequired || disabled) {
            commentIcon = <NoteRoundedIcon/>
        } else if (commentRequested) {
            commentIcon = <NoteRoundedIcon className={classes.iconButtonRequested}/>;
        } else {
            commentIcon = <NoteRoundedIcon className={classes.iconButtonRequired}/>;
        }
        return commentIcon;
    };

    handleAddComment = (field) => () => {
        let {executionQuestionNode} = this.props;
        const commentRequested = !executionQuestionNode[field + 'CommentRequested'];
        if (commentRequested) {
            const noteId = executionQuestionNode.id + '-Comment-' + field;
            setTimeout(() => {
                let noteInput = document.getElementById(noteId);
                noteInput && noteInput.focus();
            }, 0);
        }
        this.updateNodeProperty(field + 'CommentRequested', commentRequested);
    };

    renderQuestionActions = (field) => {
        let {classes, executionQuestionNode, photoDisabled, noteDisabled, diagnosticsOn, inColumn} = this.props;
        let commentRequired = executionQuestionNode[field + 'CommentEnabled'];
        let commentDisabled = !commentRequired && !executionQuestionNode[field + 'CommentRequestedEnabled'];
        let commentVisible = !executionQuestionNode[field + 'CommentHideAction']
        return (
            <div className={cn(classes.questionActionContainer, {[classes.questionActionContainerFull]: !inColumn})}>
                {
                    commentVisible &&
                    <IconButton
                        color={'secondary'}
                        data-cy='toggle-comment'
                        className={cn(classes.margin, {[classes.hide]: commentDisabled})}
                        title={strings.execution.show.addCommentTitle}
                        onClick={this.handleAddComment(field)}
                        hidden={commentDisabled}
                        disabled={noteDisabled}
                        size="large">
                        {this.renderCommentIcon(field)}
                        <ScreenReaderText>{strings.execution.show.addCommentTitle}</ScreenReaderText>
                    </IconButton>
                }
                <AddAttachmentButton photoDisabled={photoDisabled} executionQuestionNode={executionQuestionNode} field={field} classes={classes}/>
                {
                    diagnosticsOn &&
                    <IconButton
                        title={strings.buttons.diagnostics}
                        data-cy='toggle-diagnostics'
                        className={classes.margin}
                        onClick={this.toggleShowJson}
                        size="large">
                        <InfoIcon/>
                    </IconButton>
                }
                <HelpPointMarker helpPointExternalId={executionQuestionNode.procedureQuestionId}
                                 helpIconClassName={'helpIconInline'}/>
            </div>
        );
    };

    renderComment(field) {
        let {executionQuestionNode, noteDisabled} = this.props;
        return <ExecutionQuestionNote disabled={noteDisabled} questionId={executionQuestionNode.id} field={field}/>
    }

    momentFormatToDatePickerFormat = (momentFormat) => {
        return momentFormat ? momentFormat.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('h', 'hh') : null;
    };

    renderJson = (value, valueUnknown, field) => {
        const {executionQuestionNode, valueDisabled, questionName} = this.props;
        const disabled = valueUnknown || valueDisabled;
        return <ExecutionQuestionJson
            value={value}
            disabled={disabled}
            executionQuestion={executionQuestionNode}
            questionName={questionName}
            field={field}
        />;
    };

    renderDate = (value, valueUnknown, field) => {
        const {executionQuestionNode, valueDisabled, questionName} = this.props;
        const dateValue = value ? moment(value, DATE_FORMAT_STORED) : null;
        let format = this.momentFormatToDatePickerFormat(executionQuestionNode.formatDisplay);
        const disabled = valueUnknown || valueDisabled;
        return <ExecutionQuestionDate
            value={dateValue}
            format={format}
            disabled={disabled}
            executionQuestion={executionQuestionNode}
            questionName={questionName}
            field={field}
        />;
    };

    renderDateTime = (value, valueUnknown, field) => {
        const {executionQuestionNode, valueDisabled, questionName} = this.props;
        const dateValue = value ? moment.utc(value, DATETIME_FORMAT_STORED) : null;
        let format = this.momentFormatToDatePickerFormat(executionQuestionNode.formatDisplay);
        const disabled = valueUnknown || valueDisabled;
        return <ExecutionQuestionDateTime
            value={dateValue}
            format={format}
            disabled={disabled}
            executionQuestion={executionQuestionNode}
            questionName={questionName}
            field={field}
        />
    };

    renderTime = (value, valueUnknown, field) => {
        const {executionQuestionNode, valueDisabled, questionName} = this.props;
        const dateValue = value ? moment(value, TIME_FORMAT_STORED).toDate() : null;
        let format = this.momentFormatToDatePickerFormat(executionQuestionNode.formatDisplay);
        const disabled = valueUnknown || valueDisabled;
        return <ExecutionQuestionTime
            value={dateValue}
            format={format}
            disabled={disabled}
            executionQuestion={executionQuestionNode}
            questionName={questionName}
            field={field}
        />;
    };

    renderPhotoGallery(field) {
        let {executionQuestionNode, disabled} = this.props;
        if (executionQuestionNode.questionType === QUESTION_TYPES.photo.id) {
            return null;
        }
        return (
            <div>
                <ExecutionPhotoList
                    disabled={disabled}
                    executionQuestionId={executionQuestionNode.id}
                    executionId={executionQuestionNode.rootId}
                    field={field}/>
            </div>);
    }

    renderGeographic = (value, valueUnknown, field) => {
        let {executionQuestionNode, valueDisabled} = this.props;
        return <ExecutionQuestionGeographic
            disabled={valueDisabled || valueUnknown}
            field={field}
            sourceValue={value}
            questionId={executionQuestionNode.id}/>
    }

    renderGeographicMap = (value, valueUnknown, field) => {
        let {executionQuestionNode, valueDisabled} = this.props;
        return <ExecutionQuestionGeographicMap
            disabled={valueDisabled || valueUnknown}
            field={field}
            sourceValue={value}
            questionId={executionQuestionNode.id}/>
    }

    renderSignature = (value, valueUnknown, field) => {
        const {executionQuestionNode, valueDisabled} = this.props
        return (
            <ExecutionQuestionSignature
                disabled={valueDisabled || valueUnknown}
                field={field}
                questionId={executionQuestionNode.id}
            />
        )
    }

    render() {
        let {
            classes,
            disabled,
            executionQuestionNode,
            questionIndex,
            diagnosticsOn,
            troubleshootOn,
            valueDisabled,
            mobileViewPort,
            inColumn
        } = this.props;
        let {showJson} = this.state;
        let fullWidth = true;
        let fieldRender = () => {
        };

        let renderLabel = true;
        switch (executionQuestionNode.questionType) {
            case QUESTION_TYPES.yesno.id:
            case QUESTION_TYPES.select.id:
                fieldRender = this.renderSelect;
                break;
            case QUESTION_TYPES.text.id:
                fieldRender = this.renderText;
                break;
            case QUESTION_TYPES.number.id:
                fullWidth = false;
                fieldRender = this.renderNumber;
                break;
            case QUESTION_TYPES.message.id:
                fieldRender = this.renderMessage;
                renderLabel = false;
                break;
            case QUESTION_TYPES.email.id:
                fieldRender = this.renderEmail;
                break;
            case QUESTION_TYPES.phoneNumber.id:
                fieldRender = this.renderPhoneNumber;
                break;
            case QUESTION_TYPES.photo.id:
                fieldRender = this.renderPhoto;
                break;
            case QUESTION_TYPES.date.id:
                fieldRender = this.renderDate;
                break;
            case QUESTION_TYPES.datetime.id:
                fieldRender = this.renderDateTime;
                break;
            case QUESTION_TYPES.time.id:
                fieldRender = this.renderTime;
                break;
            case QUESTION_TYPES.richText.id:
                fieldRender = this.renderRichText;
                break;
            case QUESTION_TYPES.geographic.id:
                fieldRender = this.renderGeographic;
                break;
            case QUESTION_TYPES.signature.id:
                fieldRender = this.renderSignature;
                break;
            case QUESTION_TYPES.json.id:
                fieldRender = this.renderJson;
                break;
            default:
                break;
        }
        let gridClassName = questionIndex > 0 ? classes.gridField : classes.gridFieldFirst;
        const fieldRequired = executionQuestionNode.questionType === QUESTION_TYPES.photo.id ? executionQuestionNode.photoMode === 'required' : true;
        const propertyToUse = executionQuestionNode.questionType === QUESTION_TYPES.photo.id ? 'initialPhotoIds' : 'initialValue';
        const fieldIsIncompleteInitialValue = questionHasInvalidReason(executionQuestionNode, propertyToUse);
        const fieldIsIncompleteResolvedValue = questionHasInvalidReason(executionQuestionNode, 'resolvedValue');
        const fieldIsIncompleteIssueResolution = questionHasInvalidReason(executionQuestionNode, 'issueResolution');
        const fieldIsIncompleteEscalatedComment = questionHasInvalidReason(executionQuestionNode, 'escalatedComment');
        const fieldIsIncompleteEscalatedToName = questionHasInvalidReason(executionQuestionNode, 'escalatedToName');
        let initialAnswerDone = !disabled && !fieldIsIncompleteInitialValue && (hasValue(executionQuestionNode.initialValue) || hasItems(executionQuestionNode.initialPhotoIds));

        let label = renderLabel && (
            <FormLabel
                className={`${classes.positionRelative} ${classes.formLabel} ${(!valueDisabled && fieldRequired) ? 'fieldIsRequired' : ''} ${(!valueDisabled && initialAnswerDone) ? 'fieldIsAnswered' : ''}`}
                htmlFor={`initialValue-${executionQuestionNode.id}`}
                id={'question-label-' + executionQuestionNode.id}>
                <HighlightNode nodeId={executionQuestionNode.id}>{executionQuestionNode.name}</HighlightNode>
            </FormLabel>
        );

        let resolvedAnswer =
            (<div>
                <FormLabel component="legend">
                    {strings.execution.questions.resolved.labelPrefix}{executionQuestionNode.name}
                </FormLabel>
            </div>);

        return (
            <Grid container className={cn({
                [classes.inColumnQuestion]: inColumn,
            })}>
                <Grid item xs={12} className={cn(gridClassName, classes.fullWidth)}>
                    <FormControl variant="standard" className={'fieldContainer'} component="fieldset" fullWidth={fullWidth}>
                        {
                            fieldRequired && fieldIsIncompleteInitialValue &&
                            <FieldIncompleteIndicator/>
                        }
                        <div className={classes.fieldContainer}>
                            {
                                !mobileViewPort && label
                            }
                            <div className={classes.inputContainer}>
                                {
                                    renderLabel && mobileViewPort && label
                                }
                                <div className={classes.flexContainer}>
                                    {
                                        fieldRender(executionQuestionNode.initialValue, executionQuestionNode.initialValueUnknown, 'initialValue', executionQuestionNode.initialValueFormatted)
                                    }
                                    <ExecutionQuestionUnknown
                                        field={'initial'}
                                        disabled={disabled}
                                        questionId={executionQuestionNode.id}/>
                                </div>
                                {
                                    <ExecutionQuestionMessages questionId={executionQuestionNode.id}/>
                                }
                            </div>
                            {this.renderQuestionActions('initial')}
                        </div>
                        {
                            executionQuestionNode.questionType === QUESTION_TYPES.geographic.id && this.renderGeographicMap(executionQuestionNode.initialValue, executionQuestionNode.initialValueUnknown, 'initialValue')
                        }
                        {
                            (diagnosticsOn || troubleshootOn) && executionQuestionNode.initialValueByUser &&
                            <ExecutionAnsweredBy questionId={executionQuestionNode.id} valueBy={executionQuestionNode.initialValueByUser} valueDateTime={executionQuestionNode.initialValueDateTime} />
                        }
                    </FormControl>
                    <ExecutionTakePhoto
                        executionQuestionId={executionQuestionNode.id}
                        field={'initial'}/>
                    {
                        this.renderPhotoGallery('initial')
                    }
                    {
                        (executionQuestionNode.initialCommentEnabled) &&
                        <React.Fragment>
                            {this.renderComment('initial')}
                        </React.Fragment>
                    }
                </Grid>

                <Grid item xs={12} className={classes.helpContainer}>
                    <HelpPointInline nodeId={executionQuestionNode.id} helpPointExternalId={executionQuestionNode.procedureQuestionId}/>
                </Grid>
                <ExecutionQuestionIssueContainer questionId={executionQuestionNode.id}>
                    {
                        executionQuestionNode.resolvedEnabled &&
                        <Grid item xs={12}
                              className={classes.positionRelative + ' ' + classes.issueResolutionActions}>
                            {
                                fieldIsIncompleteIssueResolution &&
                                <FieldIncompleteIndicator/>
                            }
                            {
                                executionQuestionNode.escalatedFlag !== true &&
                                <React.Fragment>
                                    <Button
                                        variant={executionQuestionNode.issueResolution === 'resolved' ? 'contained' : 'outlined'}
                                        title={strings.buttons.resolved}
                                        color={'primary'} className={classes.button}
                                        data-cy='resolved'
                                        onClick={disabled ? null : this.handleResolution('resolved')}>
                                        {strings.buttons.resolved}
                                    </Button>
                                    <Button
                                        variant={executionQuestionNode.issueResolution === 'notanissue' ? 'contained' : 'outlined'}
                                        title={strings.buttons.notAnIssue}
                                        color={'primary'} className={classes.button}
                                        data-cy='not-an-issue'
                                        onClick={disabled ? null : this.handleResolution('notanissue')}>
                                        {strings.buttons.notAnIssue}
                                    </Button>
                                </React.Fragment>
                            }
                            <Button variant={executionQuestionNode.escalatedFlag ? 'contained' : 'outlined'}
                                    title={strings.buttons.escalated}
                                    color={'primary'} className={classes.button} data-cy='escalate'
                                    onClick={disabled ? null : () => this.updateNodeProperty('escalatedFlag', !(executionQuestionNode.escalatedFlag || false))}>{strings.buttons.escalated}</Button>
                        </Grid>
                    }
                    {
                        executionQuestionNode.resolvedEnabled && executionQuestionNode.escalatedFlag &&
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth={fullWidth}
                                         className={classes.resolvedAnswerContainer + ' ' + classes.fullWidth}>
                                <div className={classes.fullWidth + ' ' + classes.marginTop10}>
                                    {
                                        renderLabel &&
                                        <div>
                                            <FormLabel component="legend"
                                                       className={`fieldIsRequired ${hasValue(executionQuestionNode.escalatedComment) ? 'fieldIsAnswered' : ''}`}>
                                                {strings.execution.show.escalatedReason}
                                            </FormLabel>
                                        </div>
                                    }
                                    <div className={classes.positionRelative}>
                                        {
                                            fieldIsIncompleteEscalatedComment &&
                                            <FieldIncompleteIndicator/>
                                        }
                                        {
                                            this.renderText(executionQuestionNode.escalatedComment, false, 'escalatedComment')
                                        }
                                    </div>
                                </div>
                            </FormControl>
                            <FormControl component="fieldset" fullWidth={fullWidth}
                                         className={classes.resolvedAnswerContainer + ' ' + classes.fullWidth}>
                                <div className={classes.fullWidth}>
                                    {
                                        renderLabel &&
                                        <div>
                                            <FormLabel component="legend"
                                                       className={`fieldIsRequired ${hasValue(executionQuestionNode.escalatedToName) ? 'fieldIsAnswered' : ''}`}>
                                                {strings.execution.show.escalatedTo}
                                            </FormLabel>
                                        </div>
                                    }
                                    <div className={classes.positionRelative + ' ' + classes.marginTop10}>
                                        {
                                            fieldIsIncompleteEscalatedToName &&
                                            <FieldIncompleteIndicator/>
                                        }
                                        {
                                            this.renderText(executionQuestionNode.escalatedToName, false, 'escalatedToName')
                                        }
                                    </div>
                                </div>
                            </FormControl>
                        </Grid>
                    }
                    {
                        executionQuestionNode.resolvedEnabled && executionQuestionNode.escalatedFlag === true &&
                        <Grid item xs={12}
                              className={classes.positionRelative + ' ' + classes.issueResolutionActions + ' ' + classes.marginTop10}>
                            <Button color={'primary'} className={classes.button} data-cy='resolved'
                                    title={strings.buttons.resolved}
                                    variant={executionQuestionNode.issueResolution === 'resolved' ? 'contained' : 'outlined'}
                                    onClick={disabled ? null : this.handleResolution('resolved')}>{strings.buttons.resolved}</Button>
                            <Button color={'primary'} className={classes.button} data-cy='not-an-issue'
                                    title={strings.buttons.notAnIssue}
                                    variant={executionQuestionNode.issueResolution === 'notanissue' ? 'contained' : 'outlined'}
                                    onClick={disabled ? null : this.handleResolution('notanissue')}>{strings.buttons.notAnIssue}</Button>
                        </Grid>
                    }
                    {
                        executionQuestionNode.resolvedValueEnabled &&
                        <div className={classes.alignItemsCenter}>
                            <div
                                className={classes.resolvedAnswerContainer + ' ' + classes.marginTop8 + ' ' + classes.fullWidth}>
                                <div className={cn(classes.resolvedAnswerContainer, 'resolved-answer')}>
                                    <div className={classes.positionRelative}>
                                        {
                                            fieldIsIncompleteResolvedValue &&
                                            <FieldIncompleteIndicator/>
                                        }
                                        <div className={classes.fieldContainer}>
                                            <div className={classes.inputContainer}>
                                                {resolvedAnswer}
                                                {
                                                    fieldRender(executionQuestionNode.resolvedValue, executionQuestionNode.resolvedValueUnknown, 'resolvedValue', executionQuestionNode.resolvedValueFormatted)
                                                }
                                                <ExecutionQuestionUnknown field={'resolved'}
                                                                          questionId={executionQuestionNode.id}/>
                                            </div>
                                            {this.renderQuestionActions('resolved')}
                                        </div>
                                    </div>
                                    {
                                        (diagnosticsOn || troubleshootOn) && executionQuestionNode.resolvedValueByUser &&
                                        <ExecutionAnsweredBy questionId={executionQuestionNode.id} />
                                    }
                                    <ExecutionTakePhoto
                                        executionQuestionId={executionQuestionNode.id}
                                        field={'resolved'}/>
                                    {
                                        this.renderPhotoGallery('resolved')
                                    }
                                    {
                                        executionQuestionNode.resolvedCommentEnabled &&
                                        <div className={classes.positionRelative}>
                                            {this.renderComment('resolved')}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        executionQuestionNode.notAnIssueCommentEnabled &&
                        <React.Fragment>
                            <Grid item lg={10} md={10} sm={10} xs={10}
                                  className={classes.resolvedAnswerContainer + ' ' + classes.fullWidth}>
                                <div className={classes.positionRelative}>
                                    {
                                        renderLabel &&
                                        <div className={classes.offSetLabelContainer}>
                                            <FormLabel component="legend">
                                                {strings.execution.show.notAnIssueComment}
                                            </FormLabel>
                                        </div>
                                    }
                                    {this.renderComment('notAnIssue')}
                                </div>
                            </Grid>
                        </React.Fragment>
                    }
                </ExecutionQuestionIssueContainer>
                {
                    showJson &&
                    <Grid item xs={12}>
                        <JSONPretty id="json-pretty" data={executionQuestionNode}/>
                    </Grid>
                }
            </Grid>
        )
    }
}

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    formLabel: {
        lineHeight: "1em",
    },
    grow: {
        flexGrow: 1,
    },
    fullWidth: {
        width: '100%',
    },
    fieldContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '4px',
        marginBottom: '4px'
    },
    labelContainer: {
        flex: '0 0 100%'
    },

    inputContainer: {
        flex: "1 calc(100% - 144px)",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flex: "1 calc(100% - 36px)",
        },
        '& .MuiFormControlLabel-label': {
            position: 'relative',
        },
    },
    addLink: {textDecoration: 'none'},
    questionActionContainerFull: {
        flex: '0 0 144px',
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 36px',
        },
    },
    questionActionContainer: {
        marginTop: '-4px',
        flexDirection: 'row',
        textAlign: 'right',
        '& button': {
            [theme.breakpoints.down('sm')]: {
                padding: '6px'
            },
        }
    },
    inColumnQuestion: {
        '.form-attempted & .field-not-complete': {
            backgroundColor: '#EF4C5D',
            width: '100%',
            height: 4,
            position: 'absolute',
            left: 0,
            top: 'auto',
            bottom: -4,
            borderRadius: 4,
        },
        '.form-attempted & div.fieldContainer .field-not-complete': {
            bottom: -8,
        },
        '.form-attempted & .resolved-answer .field-not-complete': {
            bottom: -8,
        },
        '.form-attempted & .field-not-complete-highlight:after': {
            content: '" "',
            display: 'none',
        },
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: "100%",
    },
    button: {
        marginRight: 8,
        marginTop: 8,
        minWidth: '120px'
    },
    radio: {
        minWidth: '90px'
    },
    gridField: {
        paddingTop: `${theme.boxSpacing.field.spacing}px`,
    },
    gridFieldFirst: {},
    commentContainer: {
        marginTop: `${theme.boxSpacing.field.spacing}px !important`,
        marginBottom: `${theme.boxSpacing.field.spacing}px !important`,
        position: 'relative',
    },
    photoContainer: {
        display: 'block',
    },
    photoLabel: {
        display: 'flex',
    },
    diagnostics: {
        fontSize: '80%',
        color: '#666'
    },
    ruleLink: {
        textDecoration: 'underline',
        fontSize: '100%',
    },
    iconButtonRequired: {
        fill: '#b50909'
    },
    iconButtonRequested: {
        fill: '#0072ea'
    },
    iconButtonDone: {
        position: 'absolute',
        top: '-2px',
        left: '-0.85em',
        width: '0.75em',
        height: '0.75em',
        fill: theme.palette.secondary.four.main
    },
    questionIcon: {},
    photoIcon: {},
    marginTop8: {
        marginTop: 8
    },
    marginTop10: {
        marginTop: 10
    },
    helpContainer: {
        width: '100%',
    },
    questionTypeMessage: {
        fontSize: 14
    },
    resolvedAnswerContainer: {
        marginTop: 10,
        alignItems: 'center'
    },
    issueResolutionActions: {
        display: 'block',
    },
    positionRelative: {
        position: 'relative',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    offSetLabelContainer: {
        marginTop: 8,
        display: 'block',
        width: '100%',
        float: 'left',
    },
    hide: {
        width: 0,
        maxWidth: 0,
    }
});
ExecutionShowQuestionAudit.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    const executionId = executionQuestionNode.rootId;
    let executionNode = getNodeOrNull(state, executionId);
    let valueDisabled = ownProps.disabled || (executionQuestionNode && executionQuestionNode.initialValueReadOnly) || executionNode.deleted;
    let photoDisabled = ownProps.disabled || executionNode.deleted;
    let noteDisabled = ownProps.disabled || executionNode.deleted;
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    return {
        executionNode,
        executionQuestionNode: executionQuestionNode,
        executionId,
        valueDisabled: valueDisabled,
        noteDisabled: noteDisabled,
        photoDisabled: photoDisabled,
        questionName: executionQuestionNode.name,
        diagnosticsOn: userDevice?.diagnosticMode === DIAGNOSTIC_MODES.full.id,
        troubleshootOn: userDevice?.troubleshootOn === true,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withMobile()(ExecutionShowQuestionAudit)));
