import React from 'react';
import withStyles from '@mui/styles/withStyles';
import 'date-fns';
import {ComponentBase, URL_IDENTITY_BASE} from "tbf-react-library";
import ExecutionSelector from "./ExecutionSelector";
import {GLOBAL_SEARCH_TYPES, REPORT_EVENT_TYPES} from "../../util/constants";
import {NODE_IDS} from "../../reducers/graphReducer";
import {strings} from "./SopLocalizedStrings";

class ExecutionSearch extends ComponentBase {

    handleChangeSelect = (selection) => {
        if (selection == null) {
        } else {
            const {history} = this.props;
            if (selection.type === GLOBAL_SEARCH_TYPES.workItems.id || selection.type === GLOBAL_SEARCH_TYPES.users.id) {
                history.push(`/executions/${selection.id}`);
            } else if (selection.type === GLOBAL_SEARCH_TYPES.templates.id) {
                history.push(`/list/${selection.procedureType}/${selection.id}`);
            } else if (selection.type === GLOBAL_SEARCH_TYPES.teams.id) {
                window.open(`${URL_IDENTITY_BASE}/groups/${selection.id}`, '_blank');
            }
        }
    };

    render = () => {
        let {classes, isMobileSearch, autoFocus, selectRef} = this.props;
        let addClasses = isMobileSearch ? classes.mobileSearchExecutionSearch : '';
        let addMenuClass = isMobileSearch ? classes.mobileSearchMenuClass : classes.globalSearchMenuClass;
        return <>
            <ExecutionSelector
                id={'menu-search'}
                handleChange={this.handleChangeSelect}
                isFocusedResized={!isMobileSearch}
                customClass={classes.topMenuSearchExecutionSearch + ' ' + addClasses}
                customMenuClass={addMenuClass}
                placeholder={strings.inputBox.globalSearchPlaceholder}
                limit={10}
                isClearable={false}
                dropDownAsSearch={true}
                mobileView={false}
                defaultOptions={false}
                propertyName={'execution-autocomplete'}
                autoFocus={autoFocus}
                allowNearMe={true}
                searchTypes={[GLOBAL_SEARCH_TYPES.workItems.id, GLOBAL_SEARCH_TYPES.templates.id, GLOBAL_SEARCH_TYPES.users.id, GLOBAL_SEARCH_TYPES.teams.id]}
                seeMoreResults={true}
                procedureIds={[NODE_IDS.UserSubject]}
                selectRef={selectRef}
                allowContextMenu={true}
                linkOptions={true}
                eventName={REPORT_EVENT_TYPES.globalSearchUsed.name}
            />
        </>
    };
}

const styles = (theme) => ({
    topMenuSearchExecutionSearch: {
        '& .select__dropdown-indicator': {
            left: -1,
            top: -1,
        },
        '& .select__loading-indicator': {
            top: -7,
        },
        '& ~ .clearInputValueButton': {
            top: 2,
        },
        '& .select__input-container': {
            marginTop: -10,
            width: '100%',
            gridTemplateColumns: '0 1fr',
            '& input': {
                width: '100% !important',
                float: 'left !important',
                color: `${theme.palette.grey.seven.main} !important`,
            }
        },
        '& .select__placeholder ~ div:not(.select__multi-value)': {
            width: '100%'
        },
    },
    mobileSearchExecutionSearch: {
        '&#execution-autocomplete-container': {
            margin: '0 !important',
            '& .select__control': {
                background: theme.palette.primary.two.main,
                border: `1px solid ${theme.palette.grey.four.main} !important`,
                paddingLeft: 10,
                height: 45,
                minHeight: 'auto'
            }
        },
        '& .select__dropdown-indicator': {
            position: 'absolute',
            left: -1,
            top: 3,
            '& svg': {
                color: theme.palette.grey.six.main,
                fontSize: 20
            }
        },
        '& .select__loading-indicator': {
            top: -1,
        },
        '& .select__value-container': {
            paddingLeft: '18px !important',
            overflow: 'hidden',
            height: '100%'
        },
        '& .select__placeholder': {
            color: theme.palette.grey.five.main,
            transform: 'translate(18px,9px) scale(1)'
        },
        '& ~ .clearInputValueButton': {
            top: '6px !important'
        },
    },
    globalSearchMenuClass: {
        '& .select__menu': {
            '& .select__menu-list': {
                maxHeight: 670,
            }
        }
    },
    mobileSearchMenuClass: {
        '& .select__menu': {
            boxShadow: 'none',
            marginTop: 10,
            marginLeft: -40,
            width: 'calc(100% + 48px)',
            '& .select__menu-list': {
                maxHeight: '93vh !important',
            }
        }
    },
});
ExecutionSearch.propTypes = {};
export default withStyles(styles)(ExecutionSearch);
