import {standardColumns} from "../hooks/procedureHooks";
import last from "lodash/last";
import {NODE_RULE_MATCH, parseOptions, summaryToFullId} from "./executionFactory";
import {getNodeOrNull, getNodesIfPresent} from "../selectors/graphSelectors";
import {
    EXECUTION_ORDER_BY_DIRECTION,
    EXECUTION_STANDARD_PIVOT_COLUMNS,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    RULE_ACTION_TYPE,
    SELECT_RENDER_MODES
} from "../reducers/graphReducer";
import {jsonLogicApply} from "tbf-jsonlogic";
import {reportError} from "tbf-react-library";
import {getActiveChildRuleByActionOrNull, getActiveRulesForNode} from "../selectors/ruleSelectors";
import moment from "moment/moment";
import {computeListingPageOrderBy} from "./procedureFactory";
import {mapExecutionToOption} from "../layouts/components/ExecutionSelect";
import keyBy from "lodash/keyBy";

let standardFieldsById = null;
const buildStandardFields = () => {
    if (standardFieldsById == null) {
        standardFieldsById = {};
        for (let field of standardColumns) {
            standardFieldsById[field] = true;
        }
    }
}

const isOperator = (key) => {
  return !/var|or|and|\d/.test(key)
}

const guessValueType = (value) => {
    if(value === "true"|| value === "false" ) {
        return "boolean";
    } else if (moment(value,"HH:mm",true).isValid()) {
        return "time";
    }  else if  (moment(value,"YYYY-MM-DD HH:mm",true).isValid() || moment(value, "YYYY-MM-DDTHH:mm:SS.0000000Z", true).isValid()) {
        return "datetime";
    } else if  (moment(value,"YYYY-MM-DD",true).isValid()) {
        return "date";
    } else if (Array.isArray(value)) {
        return "select";
    } else if (typeof(value) === "number") {
        return  "number";
    } else {
        return "text";
    }
}


export const extractJsonLogicVars = (jsonLogic, results, includeType = false ,type = undefined) => {
    if (isJsonLogicPrimitive(jsonLogic)) {
        return;
    }
    for (let [key, value] of Object.entries(jsonLogic)) {
        const isVar = key === 'var';
        if (isVar && includeType) {
            const parts = value.split('_');
            results.push({
                var: value,
                type: type,
                nodeId: parts.length >= 2 ? parts[0] : null,
                property: parts.length >= 2 ? parts[1] : null
            })
        } else if (isVar && !includeType) {
            results.push(value);
        } else if(isOperator(key)) {
            const isArray = Array.isArray(value);
            if(isArray) {
                const lastValue = value?.[value?.length - 1];
                type = guessValueType(lastValue);
            }
        }
        extractJsonLogicVars(value, results,includeType, type);
    }
}

export const upgradeJsonLogic = (jsonLogic, multiselectPaths) => {
    let upgradeA = replaceEmptyWithNull(jsonLogic);
    return replaceSomeWithIn(upgradeA, multiselectPaths);

}
const isJsonLogicPrimitive = (jsonLogic) => {
    return jsonLogic == null || typeof jsonLogic === 'string' || typeof jsonLogic === 'number' || jsonLogic instanceof String || typeof jsonLogic === 'boolean';
}
/**
 * We use to use is_empty, however that fails for 0 and is not ideal. So we are using is null instead.
 *
 * @param jsonLogic
 * @returns {{}|string|number|boolean}
 */
export const replaceEmptyWithNull = (jsonLogic) => {
    const visitor = {
        "!": (value) => {
            if (value.var) {
                return {"!=": [value, null]}
            }
        },
        "!!": (value) => {
            if (value.var) {
                return {"==": [value, null]}
            }
        }
    };
    return jsonLogicVisitor(jsonLogic, visitor);
}
/**
 * This will replace: "some": [{"var":"xx"},{"in":[{"var":""},[1]]}]
 * with:  {"in":[{"var":"xx"},[1]]}
 *
 * By making any_in work for multisleects in the same way as selects it will not break the rule as the
 * changes the type.
 * @param jsonLogic
 * @returns {null|*}
 */
export const replaceSomeWithIn = (jsonLogic) => {
    const visitor = {
        "some": (value) => {
            if (Array.isArray(value) && value.length === 2 && value[0].var && value[1].in) {
                return {"in": [value[0], value[1].in[1]]}
            }
        }
    };
    return jsonLogicVisitor(jsonLogic, visitor);
}
export const jsonLogicVisitor = (jsonLogic, visitor) => {
    if (isJsonLogicPrimitive(jsonLogic)) {
        return jsonLogic;
    }
    let revised;
    if (Array.isArray(jsonLogic)) {
        let revisedItems = [];
        for (let item of jsonLogic) {
            const convertedItem = jsonLogicVisitor(item, visitor)
            revisedItems.push(convertedItem)
        }
        revised = revisedItems;
    } else {
        revised = {};
        for (let [key, value] of Object.entries(jsonLogic)) {
            const before = (visitor[key] && visitor[key](value)) || {[key]: value};
            const newKey = Object.keys(before)[0];
            const newValue = before[newKey];
            revised[newKey] = jsonLogicVisitor(newValue, visitor);
        }
    }
    return revised;
}
/**
 * Find the referenced properties in the json logic.
 *
 * As we store fields as XXX_QuestionId_finalValue we can take the 2nd last component.
 * But for standard properties like key, createdDateTime we store as <procedureid>_key.
 * For these we need to take the last component.
 *
 * @param jsonLogic
 * @returns {*[]}
 */
export const extractJsonLogicProperties = (jsonLogic) => {
    buildStandardFields();
    let vars = [];
    extractJsonLogicVars(jsonLogic, vars);
    let properties = [];
    for (let v of vars) {
        const parts = v.split('_');
        if (!parts.length >= 2) {
            continue;
        }
        const lastPart = last(parts);
        const isStandardProperty = standardFieldsById[lastPart];
        if (isStandardProperty) {
            properties.push(lastPart);
        } else {
            let property = parts[parts.length - 2];
            properties.push(property);
        }
    }
    return properties;
}

export const isStandardField = (fieldName) => {
  buildStandardFields()
  const isStandardProperty = standardFieldsById[fieldName];
  return !!isStandardProperty
}

export const isStandardPivotField = (fieldName) => {
    const isStandardProperty = EXECUTION_STANDARD_PIVOT_COLUMNS[fieldName];
    return !!isStandardProperty
}

export const extractJsonLogicReturnFields = (jsonLogic) => {
    buildStandardFields();
    let vars = [];
    extractJsonLogicVars(jsonLogic, vars);
    let properties = [];
    for (let v of vars) {
        const parts = v.split('_');
        if (parts.length !== 6 || !v.includes("_link_toNode_")) {
            continue;
        }
        const lastPart = last(parts);
        const isStandardProperty = standardFieldsById[lastPart];
        if (!isStandardProperty) {
            let property = parts[parts.length - 2];
            properties.push(property);
        }
    }
    return properties;
}
export const validateConditionQueryRule = (state, rule, name, ruleRootNodeId, allowExternalReferences = false) => {
    let matches;
    let pattern = NODE_RULE_MATCH('jsonLogic');
    let compileWarning = '';
    do {
        matches = pattern.exec(rule);
        if (matches) {
            let matchId = matches[2];
            if (matchId === 'execution') {
                continue;
            }
            let matchedNode = state.nodes[matchId];
            const isProcedureTypeNode =
                [
                    NODE_TYPE_OPTIONS.ProcedureQuestion,
                    NODE_TYPE_OPTIONS.ProcedureTask,
                    NODE_TYPE_OPTIONS.ProcedureRoot,
                    NODE_TYPE_OPTIONS.ProcedureStep
                ].includes(matchedNode?.type);

            // if ruleRootNodeId is provided and is a ProcedureTypeNode, check if it matched the matchedNode.rootId
            // if not set it to undefined
            //
            // this checks if matchNode (reference of rule) is under the same template with ruleNode
            if(isProcedureTypeNode && ruleRootNodeId && ruleRootNodeId !== matchedNode?.rootId && !allowExternalReferences) {
                matchedNode = undefined;
            }

            if (matchedNode === undefined) {
                compileWarning += `\n${name} references a field that does not exist.`;
            } else if (matchedNode.deleted || matchedNode.parentDeleted) {
                compileWarning += `\n${name} references the field [${matchedNode.name}] that has been deleted.`;
            }
        }
    } while (matches);
    return compileWarning;
};

export const SELECTOR_FILTER_TYPE = {
    select: {id: 'select'},
    link: {id: 'link'}
}
export const computeFilter = (state, nodeId, type = SELECTOR_FILTER_TYPE.select.id, dependencyTargetNodeId = nodeId) => {
    const ruleFilter = (r) => {
        let predicate;
        switch (type) {
            case SELECTOR_FILTER_TYPE.select.id:
                predicate = r.addExistingOn;
                break;
            case SELECTOR_FILTER_TYPE.link.id:
                predicate = r.linkToQuestionOn;
                break;
        }
        return predicate;
    }
    let deps = [];
    let rules = getActiveRulesForNode(state, nodeId);
    rules = rules.filter(ruleFilter);
    rules.forEach(r => {
        deps.push({from: r.id, to: dependencyTargetNodeId, properties: ['evaluatedValue']});
    });
    if (type === SELECTOR_FILTER_TYPE.select.id) {
        rules = rules.filter(a => a.evaluatedValue);
    }
    let conditions = [];
    let procedureIds = [];
    let orderBy = [];

    // Search clause
    let selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + `-${nodeId}_initialValue`;
    let selector = getNodeOrNull(state, selectorId);
    let search;
    if (selector) {
        search = selector.searchTerm?.toLowerCase();
    }

    let filterReady = true;
    for (let rule of rules) {
        let ruleProcedureIds = rule.linkMatchProcedureIds || [];
        let filterRule = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.filter.id);
        let orderRule = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.collectionOrder.id);
        deps.push({
            from: rule.id,
            to: dependencyTargetNodeId,
            properties: ['conditionQuery', 'conditionQueryPartial', 'linkMatchProcedureIds', 'ruleIds'],
        });
        if (filterRule) {
            deps.push({
                from: filterRule.id,
                to: dependencyTargetNodeId,
                properties: ['conditionQuery', 'conditionQueryPartial']
            });
        }
        if (orderRule) {
            deps.push({
                from: orderRule.id,
                to: dependencyTargetNodeId,
                properties: ['calculateValueQuery', 'orderByDirection'],
            });
        }
        const computeListingPageOrderByResult = computeListingPageOrderBy(state, rule.id) ?? [];
        orderBy = [...orderBy, ...computeListingPageOrderByResult]
        filterReady = filterReady && (filterRule === null || filterRule.conditionQuery === null || filterRule.conditionQueryPartial !== undefined);
        let condition = (filterRule && filterRule.conditionQueryPartial) || 'true';
        if (condition === 'false' || ruleProcedureIds.length === 0) {
            // If filter is false user cannot select anything
            continue;
        }
        ruleProcedureIds.forEach(id => procedureIds.push(id));
        if (rules.length === 1) {
            // If only 1 filter no need to add the extra conditions
            if (condition === 'true') {
                break;
            } else {
                conditions.push(condition);
            }
        } else {
            let procedureIdFilter = `{"in":[{"var":"procedureId"},${JSON.stringify(ruleProcedureIds)}]}`;
            let parts = condition === 'true' ? procedureIdFilter : `{"and":[${procedureIdFilter},${condition}]}`;
            conditions.push(parts);
        }
    }

    if (procedureIds.length === 0) {
        return {filter: null, filterReady, dependencies: deps};
    }

    // where clause
    let useConditions;
    if (conditions.length > 1) {
        useConditions = `{"or":[${conditions.join(',')}]}`;
    } else if (conditions.length > 0) {
        useConditions = conditions[0];
    }

    // order clause
    let useOrder;
    let userOrderDirection;
    if (orderBy.length > 1) {
        const fields = orderBy.map(o => o.orderBy);
        const directions = orderBy.map(o => o.orderByDirection);
        useOrder = `{"or":[${fields.join(',')}]}`;
        userOrderDirection = `{"or":[${directions.join(',')}]}`;
    } else if (orderBy.length > 0) {
        useOrder = orderBy[0].orderBy;
        userOrderDirection = orderBy[0].orderByDirection;
    } else if (type === SELECTOR_FILTER_TYPE.select.id) {
        useOrder = 'title';
        userOrderDirection = EXECUTION_ORDER_BY_DIRECTION.ascending.id;
    }

    return {
        filter: {procedureIds: procedureIds, where: useConditions, orderBy: useOrder, orderByDirection: userOrderDirection, search},
        filterReady,
        dependencies: deps,
    };
}

export const computeExecutionSelectOptions = (state, currentNode, returnDependencies = false) => {
    const questionId = currentNode.id;
    const dependencies = [];
    const executionQuestionNode = getNodeOrNull(state, questionId);
    const procedureIds = currentNode?.selectExecutionFilter?.procedureIds;
    const workItemSearch = executionQuestionNode?.selectRenderMode === SELECT_RENDER_MODES.autocomplete.id;
    let loadExecutionsUrl = NODE_IDS.ExecutionQuestionSelect(currentNode);
    const loadExecutionsResourceSync = getNodeOrNull(state, loadExecutionsUrl);
    let options, optionsParsed;
    let optionsByValue = keyBy(currentNode.optionsParsed, (o) => o.value);
    if (loadExecutionsResourceSync && loadExecutionsResourceSync.loaded) {
        const loadedExecutionNodes = getNodesIfPresent(state, (loadExecutionsResourceSync && loadExecutionsResourceSync.nodeIds) || []);
        const existingExecutionOptions = ((loadExecutionsResourceSync && loadExecutionsResourceSync.nodeIds) || []).reduce((map, nodeId) => {
            let id = summaryToFullId(nodeId);
            if (optionsByValue[id]) {
                map[id] = {
                    value: id,
                    label: optionsByValue[id].label,
                }
            }
            return map;
        }, {});
        for (const execution of loadedExecutionNodes) {
            dependencies.push({from: execution.id, to: questionId, properties: ['deleted', 'title']});
        }
        // For existing execution nodes that aren't loaded, assume not deleted and available
        let executions = loadedExecutionNodes.filter(a => a.deleted !== true);
        const includeTemplateParent = workItemSearch ? !!(procedureIds && procedureIds.length > 1) : true;
        const executionsOptions = keyBy(mapExecutionToOption(executions, false, includeTemplateParent, false), o => o.value)
        options = loadExecutionsResourceSync.nodeIds.reduce((list, id) => {
            id = summaryToFullId(id);
            let option = executionsOptions[id];
            if (!option) {
                option = existingExecutionOptions[id];
            }
            if (option) {
                list.push(`${option.value}=${option.label}`);
            }
            return list;
        }, []).join("\n");
        optionsParsed = parseOptions(options, true);
    } else {
        options = currentNode.options ?? "";
        optionsParsed = currentNode.optionsParsed ?? [];
    }
    
    dependencies.push({from: loadExecutionsUrl, to: questionId, properties: ['loading', 'loaded', 'nodeIds']});

    return [options, optionsParsed, returnDependencies ? dependencies : undefined];
}

export const convertJsonLogicForQuery = (state, node, jsonLogic, format) => {
    if (!jsonLogic) {
        return null;
    }
    let dependencies = [];
    let query = JSON.parse(jsonLogic);
    let converted = visitJsonLogicForQuery(state, node, query, format);
    if (converted.containsUserProperties && converted.containsUserProperties.length) {
        dependencies.push({from: NODE_IDS.User, to: node.id, properties: converted.containsUserProperties});
    }
    let result = format === 'query' ? JSON.stringify(converted.jsonLogic) : converted.jsonLogic;
    return {result, dependencies}
}
export const visitJsonLogicForQuery = (state, node, jsonLogic, format) => {
    buildStandardFields();
    if (isJsonLogicPrimitive(jsonLogic)) {
        return {jsonLogic: jsonLogic, containsSearchTerm: false, containsUserProperties: []};
    }
    let convertedJsonLogic = {};
    let containsSearchTerm = false;
    let placeholder = false;
    let containsUserProperties = [];
    if (Array.isArray(jsonLogic)) {
        let results = [];
        for (let item of jsonLogic) {
            const next = visitJsonLogicForQuery(state, node, item, format);
            results.push(next.jsonLogic);
            containsSearchTerm = containsSearchTerm || next.containsSearchTerm;
            containsUserProperties.push(...next.containsUserProperties);
        }
        convertedJsonLogic = results;
    } else {
        for (let [key, value] of Object.entries(jsonLogic)) {
            if (key === 'var') {
                // for execution_link_toNode_ProcedureRoot1_ProcedureQuestion1_finalValue we want to have just question id + property
                const isString = value && value.startsWith;
                const isLinkRef = isString && (value.startsWith('execution_link_toNode_') || value.startsWith(node.rootId + '_link_toNode_'));
                const isSelfRef = isString && (format === 'summarySelf' || format === 'querySelf') && !value.startsWith('user_');
                const isUserRef = isString && (format === 'summarySelf' || format === 'querySelf') && value.startsWith('user_');
                const isItemRef = value === "";
                if (isLinkRef || isSelfRef) {
                    const parts = value.split('_');
                    const nodeId = parts[parts.length - 2];
                    const property = parts[parts.length - 1];
                    if (format === 'query' || format === 'querySelf') {
                        // This format is sent to the API in the where clause.
                        convertedJsonLogic[key] = `${nodeId}_${property}`;
                    } else if (format === 'summaryLinked' || isSelfRef) {
                        const isStandardField = standardFieldsById[property];
                        if (isStandardField) {
                            convertedJsonLogic[key] = `execution.${property}`;
                        } else {
                            const useProperty = property.replace('finalValue', 'value')
                            // Summary format is used query filtering
                            convertedJsonLogic[key] = `execution.fields.${nodeId}.${useProperty}`;
                        }
                    } else {
                        throw new Error('Unknown format: ' + format)
                    }
                    containsSearchTerm = true;
                    continue;
                } else if (isUserRef) {
                    const parts = value.split('_');
                    const property = parts[parts.length - 1];
                    containsUserProperties.push(property);
                }
                if (isItemRef) {
                    placeholder = true;
                }
            }
            const next = visitJsonLogicForQuery(state, node, value, format);
            convertedJsonLogic[key] = next.jsonLogic;
            containsSearchTerm = containsSearchTerm || next.containsSearchTerm;
            containsUserProperties.push(...next.containsUserProperties);
        }
    }

    // Evaluate the branch or trim it
    if (!containsSearchTerm && !placeholder) {
        convertedJsonLogic = evaluateRule(state, JSON.stringify(convertedJsonLogic), null, convertedJsonLogic);
    } else if (convertedJsonLogic["and"]) {
        // If any false, then false
        // If all true, then true
        // Otherwise, strip out all non-true parts
        if (convertedJsonLogic["and"].find(a => a === false) !== undefined) {
            convertedJsonLogic = false;
        } else {
            let parts = convertedJsonLogic["and"].filter(a => a !== true);
            if (parts.length === 0) {
                convertedJsonLogic = true;
            } else if (parts.length === 1) {
                convertedJsonLogic = parts[0];
            } else {
                convertedJsonLogic["and"] = parts;
            }
        }
    } else if (convertedJsonLogic["or"]) {
        // If any true, then true
        // If all false, then false
        // Otherwise, strip out all non-false parts
        if (convertedJsonLogic["or"].find(a => a === true) !== undefined) {
            convertedJsonLogic = true;
        } else {
            let parts = convertedJsonLogic["or"].filter(a => a !== false);
            if (parts.length === 0) {
                convertedJsonLogic = false;
            } else if (parts.length === 1) {
                convertedJsonLogic = parts[0];
            } else {
                convertedJsonLogic["or"] = parts;
            }
        }
    }

    return {
        containsUserProperties: containsUserProperties,
        containsSearchTerm: containsSearchTerm,
        jsonLogic: convertedJsonLogic
    }
}
export const evaluateRule = (state, rule, node, valueOnFail) => {
    let convertedRule = rule;
    try {
        convertedRule = rule.replace(NODE_RULE_MATCH('jsonLogic'), "$1state.nodes.$2.$3");
        let result = jsonLogicApply(JSON.parse(convertedRule), {state: state});
        if (result === '') {
            return null;
        }
        return result;
    } catch (error) {
        let data = {
            node: node,
            rule: rule,
            convertedRule: convertedRule
        };
        reportError(`Error evaluating visibility rule [${convertedRule}]`, error, data);
    }
    return valueOnFail;
};
