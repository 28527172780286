import React, {useCallback} from 'react';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {Permissions} from "../../permissions";
import UnDeleteIcon from '@mui/icons-material/RestoreFromTrashRounded';
import CloseIcon from '@mui/icons-material/Close';
import {strings} from "../components/SopLocalizedStrings";
import {useCallbackPatchNode, useNodeOrNull, useNodeSchemaOrNull} from "../../hooks/nodeHooks";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {useHasExecutionPermission} from "../../hooks/executionHooks";
import {toast} from 'react-toastify';
import TbfSopAction from '../components/common/TbfSopAction';

const NodeDelete = React.forwardRef(({menuItem, iconButton, nodeId, friendlyName, eventName, confirmDelete = true, disabled}, ref) => {
    const node = useNodeOrNull(nodeId)
    const nodeSchema = useNodeSchemaOrNull(node?.type)

    const restore = useCallbackPatchNode({id: nodeId, deleted: !node?.deleted})
    const handleRestoreClick = useCallback((args) => {
        if (node.parents && node.parents.length > 0 && node.parents.find((parent) => parent.deleted)) {
            toast.error(strings.execution.errors.cannotRestoreParentDeleted);
            return;
        }
        return restore(args);
    }, [restore, node])

    const name = friendlyName || nodeSchema?.displayName || 'item'
    const handleDeleteClick = useCallbackPatchNode({
        id: nodeId,
        deleted: !node?.deleted
    }, confirmDelete ? `Are you sure you want to delete this ${name}?` : null)
    let requiredPermission = nodeTypeToPermission[node?.type] || 'not-loaded';
    const isProcedure = node.type === NODE_TYPE_OPTIONS.ProcedureRoot || node.type === NODE_TYPE_OPTIONS.ProcedureRule;
    const isExecution = node.type === NODE_TYPE_OPTIONS.ExecutionRoot;
    const hasProcedurePermission = useHasProcedurePermission(isProcedure ? node?.rootId : null, requiredPermission);
    const hasExecutionePermission = useHasExecutionPermission(isExecution ? node?.rootId : null, requiredPermission);
    if (!node || node.destroyed) {
        return null
    }
    if (isProcedure && !hasProcedurePermission) {
        return null;
    }
    if (isExecution && !hasExecutionePermission) {
        return null;
    }
    const passPermission = isProcedure || isExecution ? null : requiredPermission;
    const deleted = node.deleted;
    return (<TbfSopAction
        menuItem={menuItem}
        iconButton={iconButton}
        title={(deleted ? strings.buttons.restore : strings.buttons.delete) + ' this ' + name}
        dataCy={deleted ? 'GraphUnDelete' : 'GraphDelete'}
        iconComponent={deleted ? UnDeleteIcon : (confirmDelete ? DeleteIcon : CloseIcon)}
        onClick={deleted ? handleRestoreClick : handleDeleteClick}
        permission={passPermission}
        disabled={disabled}
        ref={ref}
        eventName={eventName}
        nodeId={nodeId}
    >
        {deleted ? strings.buttons.restore : strings.buttons.delete}
    </TbfSopAction>)
})

const nodeTypeToPermission = {
    'ProcedureRoot': Permissions.procedure.delete,
    'ProcedureRule': Permissions.procedure.edit,
    'ExecutionRoot': Permissions.execution.delete,
    'ProjectRoot': Permissions.project.delete,
    'ProcedureSchemaProperty': Permissions.procedure.edit,
};
export default NodeDelete;
