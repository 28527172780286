import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getDirtiesById, getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import LoadHelpPoints from '../../manuals/layouts/LoadHelpPoints';
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {ComponentBase} from "tbf-react-library";
import {debounce, keyBy} from "lodash";
import {isRecent} from "../../util/util";
import {HOURS_1, MINUTES_5} from "../../util/constants";
import {isDeepEqual} from '@mui/x-data-grid/internals';

class ExecutionLoadDependencies extends ComponentBase {

    constructor(props, context) {
        super(props, context);
        this.state = {
            executionListPathsDebounce: false,
        }
    }

    mounted = true;

    componentDidUpdate(prevProps) {
        const {executionListPaths} = this.props;
        const listPathsChanged = !isDeepEqual(executionListPaths, prevProps.executionListPaths);
        if (listPathsChanged) {
            this.setState({executionListPathsDebounce: true});
            this.debounceExecutionListPaths(this);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    debounceExecutionListPaths = debounce((t) => {
        if (!t.mounted) {
            return;
        }
        t.setState({executionListPathsDebounce: false})
    }, 200)

    render() {
        let {
            executionProjectId,
            executionProcedureId,
            loadProcedureIds,
            preloadExecutionIds,
            loadExecutionIds,
            reloadInterval,
            scopePaths,
            scopePathKeys,
            executionListPaths,
            preloadExecutionListPaths,
            photoPaths,
            preloadProcedureIds
        } = this.props;
        const {executionListPathsDebounce} = this.state;
        return (
            <>
                {
                    executionProjectId &&
                    <GraphResourceLoad
                        resourcePath={NODE_IDS.Project(executionProjectId)}
                        friendlyName={strings.project.name}
                        nodeId={executionProjectId}
                        nodeType={'ProjectRoot'}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                    />
                }
                {
                    preloadExecutionListPaths &&
                    preloadExecutionListPaths.map((path) =>
                        <GraphResourceLoad
                            key={path}
                            resourcePath={path}
                            friendlyName={strings.execution.namePlural}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={MINUTES_5}
                            hideLoader={true}
                            incrementalLoadOff={true}
                            hideOfflineWarnings={true}
                            lowPriority={true}
                        />
                    )
                }
                {
                    executionListPaths &&
                    executionListPaths.filter((path) => {
                        if (!executionListPathsDebounce) {
                            return true;
                        }
                        return !path.includes("q=");
                    }).map((path) =>
                        <GraphResourceLoad
                            key={path}
                            resourcePath={path}
                            friendlyName={strings.execution.namePlural}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={MINUTES_5}
                            hideLoader={true}
                            incrementalLoadOff={true}
                            hideOfflineWarnings={true}
                        />
                    )
                }
                {
                    photoPaths && photoPaths.map(path => (<GraphResourceLoad
                        key={reloadInterval}
                        resourcePath={path}
                        friendlyName={strings.photo.namePlural}
                        nodeType={'Photo'}
                        reloadIntervalMs={reloadInterval}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                        lowPriority={true}
                    />))
                }
                {
                    executionProcedureId &&
                    <LoadHelpPoints externalId={executionProcedureId}/>
                }
                {
                    scopePaths &&
                    scopePaths.map((scopePath, i) => <GraphResourceLoad
                        key={scopePathKeys[i]}
                        resourcePath={scopePath}
                        friendlyName={strings.execution.namePlural}
                        nodeType={'ExecutionRoot'}
                        reloadIntervalMs={reloadInterval}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                    />)
                }
                {
                    loadProcedureIds && loadProcedureIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={NODE_IDS.ProcedureFull(id)}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ProcedureRoot'}
                            reloadIntervalMs={HOURS_1}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                            firstLoadQuickOff={true}
                        />))
                }
                {
                    preloadProcedureIds && preloadProcedureIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={NODE_IDS.ProcedureFull(id)}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ProcedureRoot'}
                            reloadIntervalMs={HOURS_1}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                            firstLoadQuickOff={true}
                            offline={true}
                        />))
                }
                {
                    preloadExecutionIds && preloadExecutionIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={`/executions?id=${id}`}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={reloadInterval}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                            lowPriority={true}
                        />))
                }
                {
                    loadExecutionIds && loadExecutionIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={`/executions?id=${id}`}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={reloadInterval}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                        />))
                }
            </>
        )
    }
}

const styles = () => ({});
ExecutionLoadDependencies.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.executionId;
    let executionNode = getNodeOrNull(state, nodeId);

    if(executionNode?.type !== NODE_TYPE_OPTIONS.ExecutionRoot) {
        executionNode = null;
        nodeId = "unknown";
    }

    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const troubleshootOn = userDevice?.troubleshootOn === true;

    // #1 Get all required full executions
    // #1.1 Load auto created ones in full
    // Unsure if I should load these
    let autoCreatedExecutionIds = []
    if (troubleshootOn) {
        let rules = getNodesIfPresent(state, executionNode?.rules);
        autoCreatedExecutionIds = rules.filter(a => a.createExecutionIds).flatMap(a => a.createExecutionIds);
    }
    let preloadExecutionIds = [...new Set([...(executionNode?.resourceDependencies?.preloadExecutionIds ?? []), ...autoCreatedExecutionIds])];
    let loadExecutionIds = [...(executionNode?.resourceDependencies?.executionIds ?? [])];
    return {
        nodeId: nodeId,
        executionProcedureId: executionNode?.procedureId,
        executionProjectId: executionNode?.projectId,
        loadProcedureIds: executionNode?.resourceDependencies?.procedureIds,
        preloadExecutionIds: preloadExecutionIds,
        loadExecutionIds: loadExecutionIds,
        scopePaths: executionNode?.resourceDependencies?.scopePaths,
        scopePathKeys: executionNode?.resourceDependencies?.scopePathKeys,
        reloadInterval: (ownProps.reloadInterval * 1000) || executionNode?.reloadInterval,
        executionListPaths: executionNode?.resourceDependencies?.executionListPaths,
        preloadExecutionListPaths: executionNode?.resourceDependencies?.preloadExecutionListPaths,
        photoPaths: executionNode?.resourceDependencies?.photoPaths,
        preloadProcedureIds: executionNode?.resourceDependencies?.preloadProcedureIds
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionLoadDependencies));
